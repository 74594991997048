import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory,useLocation  } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'

const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();

    const [data, setData] = React.useState({
        directorio: [],
    });

    useEffect(() => {

        getDatos()

        return () => {

        };

    }, []);

    const getDatos = async () => {

        // let idUsuario = await AsyncStorage.getItem('idUsuario');
        const URL = `${API_URL}/getDirectorioMunicipios`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    idUsuario: location.state.idUsuario,
                    claveMunicipio:CLAVE_MUNICIPIO
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
      //      alert(resp)

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                setData({
                    ...data,
                    directorio: datos
                });
            } else if (resp.msg == "300") {

            } else if (resp.msg == "400") {
                alert("algo salió mal")
            }

        } catch (error) {

        }

    }

    return (
        <>
            {/*position:'fixed',top:0,width:'100%'*/}
            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Directorio</Text>
            </Header>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {data.directorio.map(ele => {

                    return (
                        /*   <Link to="/Dashboard/Reporte" tyle={{ width: '90%', height: 80, alignItems: 'flex-start', borderColor: '#D8D8D8', borderWidth: 1.5, borderRadius: 10, justifyContent: 'center', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                               <img src={ele.icono} style={{ width: 70 }} />
                               <Text style={{marginLeft:10}}>{ele.servicio}</Text>
                       </Link>*/
                      
                        <a href={`tel:${ele.contacto}`} style={{ borderStyle: 'solid', borderColor: '#D8D8D8', borderWidth: 1.5, borderRadius: 10, alignItems:'center', width: '90%', height: 80, justifyContent:'flex-start', marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                            <img src={ele.icono} style={{ width: 50,marginLeft:10 }} />
                            <Text style={{marginLeft:15,fontSize:18}}>{ele.servicio}</Text>
                            
                          
                            <PhoneFilled style={{position:'absolute',right:35,marginTop:15,color:'grey'}}/>
                          
                        </a>
                     
                    )

                })}
            </div>


        </>
    )
}

export default Primero;