import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Login from './paginas/Login';
import Principal from './paginas/Principal';
import Registro from './paginas/Registro';
import LlaveroSesion from './paginas/LlaveroSesion';
import ValidarRegistro from './paginas/ValidarRegistro';
import AuthApi from './componentes/Contexto/AuthApi';
import Dashboard from './paginas/Dashboard'
import history from './componentes/Contexto/history';
import LoginLlavero from './paginas/LoginLlavero';
import MapaCalor from './paginas/MapaCalor';
import { API_URL, CLAVE_MUNICIPIO, APP } from './componentes/WebServices/Variables'
import * as Vibrant from 'node-vibrant';
import Cookies, { set } from 'js-cookie';
import "./App.css";



var ultimaRuta = "Inicio"
var colorPrimary = "#000"
var idUsuario = ""
var correoUsuario = ""

function App() {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };


  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);



  const [auth, setAuth] = useState({ autenticacion: false, rol: '', id: '', correo: '' });

  const leerCookie = () => {
    Cookies.remove("tramitesLista")

    /*if(Cookies.get('ultimaRuta')!=null){
      console.log("ULTIMAAAAAAAAA: "+Cookies.get('ultimaRuta'))
    ultimaRuta = Cookies.get('ultimaRuta');
    }*/

    var user = Cookies.get('galleta');

    if (user != null) {
      var arr = JSON.parse(user);
      if (arr[0] === "true") {
        setAuth({ autenticacion: true, rol: arr[1], id: arr[2], correo: arr[3] });

      } else {
        setAuth({ autenticacion: false, rol: '', id: '', correo: '' });
      }
    }



  }

  useEffect(() => {
    //getColores()

    /* if (Cookies.get('colorPrimary') != null) {
       //alert("si hay color")
       colorPrimary = Cookies.get('colorPrimary');
     } else {
       Vibrant.from(`${API_URL}/getImagenMunicipio/${CLAVE_MUNICIPIO}/?${Date.now()}`).getPalette()
         .then(palette => {
 
           const paleta = palette.DarkMuted._rgb;
  
           const el = document.querySelector("meta[name='theme-color']");
           const hexa = "#" + fullColorHex(paleta[0], paleta[1], paleta[2]);
           console.log(hexa)
 
           hexa="#000"
           colorPrimary = hexa;
           
           el.setAttribute('content', hexa)
         })
         .catch(error => {
           // handle errors
         });
     }*/


    Vibrant.from(`${API_URL}/getImagenMunicipio/${CLAVE_MUNICIPIO}/?${Date.now()}`).getPalette()
      .then(palette => {
        
        var paleta;
        if (CLAVE_MUNICIPIO == '112') {
           paleta = palette.Vibrant.hex;
        } else {
          paleta = palette.DarkMuted.hex;
        }

        //console.log(paleta)
        const el = document.querySelector("meta[name='theme-color']");
        //     const hexa = "#" + fullColorHex(paleta[0], paleta[1], paleta[2]);
        //   console.log(hexa)

        colorPrimary = paleta;
        el.setAttribute('content', paleta)
      })
      .catch(error => {
        console.log(error)
        // handle errors
      });



    leerCookie();
  }, []);

  /*const getColores = async () => {
    const URL = `${API_URL}/login`;
    let contraEncriptada = CryptoJS.AES.encrypt(values.password, 'royaleWithCheese').toString();
    try {
      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          correo: values.correo,
          password: contraEncriptada
        }),
        headers: { "Content-Type": "application/json" }
      });
      const resp = await response.json();

      if (resp.msg === "200") {

      } else if (resp.msg === "300") {

      }

      else if (resp.msg === "400") {

      }

    } catch (error) {
     
    }
  }*/


  var fullColorHex = function (r, g, b) {
    var red = rgbToHex(r);
    var green = rgbToHex(g);
    var blue = rgbToHex(b);
    return red + green + blue;
  }

  var rgbToHex = function (rgb) {
    var hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  }


  return (
    <AuthApi.Provider value={{ auth, setAuth }}>
      <Router history={history}>

        <div>
          <Routes />
        </div>

      </Router>
    </AuthApi.Provider>
  )
}


const Routes = () => {
  const Auth = useContext(AuthApi);
  return (
    <Switch>
      <ProtectedLogin exact path="/" component={Principal} auth={Auth.auth} />
      <ProtectedLogin exact path="/Login" component={Login} auth={Auth.auth} />
      <ProtectedLogin exact path="/MapaCalor/:idDireccion/:idDepartamento/:defaultLat/:defaultLong/:marcadores?" component={MapaCalor} auth={Auth.auth} />
      <ProtectedLogin exact path="/Registro" component={Registro} auth={Auth.auth} />
      <ProtectedLogin exact path="/LlaveroSesion" component={LlaveroSesion} auth={Auth.auth} />
      <ProtectedLogin exact path="/ValidarRegistro" component={ValidarRegistro} auth={Auth.auth} />
      {/*  <Route path="/LoginLlavero/:id" component={LoginLlavero} colorPrimary={colorPrimary}/>*/}
      <ProtectedLogin exact path="/LoginLlavero/:id" component={LoginLlavero} auth={Auth.auth} />
      <ProtectedRoute path="/Dashboard" auth={Auth.auth} component={Dashboard} />

      
    </Switch>
  )
}

const ProtectedRoute = ({ auth, component: Component, ...rest }) => {

  useEffect(() => {
    console.log("ProtectedRoute")
    console.log(auth)
    return () => { };
  }, []);

  return (
    <Route
      {...rest}
      render={() => auth.autenticacion ? (
        <Component rol={auth.rol}
          id={auth.id}
          correo={auth.correo}
          //  ultimaRuta={ultimaRuta}
          colorPrimary={colorPrimary}
        />
      ) :
        (
          <Redirect to="/" />
        )
      }
    />
  )
}

const ProtectedLogin = ({ auth, component: Component, ...rest }) => {

  useEffect(() => {

    return () => { };
  }, []);


  return (
    <Route
      {...rest}
      render={() => !auth.autenticacion ? (
        <Component colorPrimary={colorPrimary} />
      ) :
        (
          <Redirect to="/Dashboard" />
        )
      }
    />
  )
}
export default App;
