import React, { useEffect, useRef, memo } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout } from 'antd';
import { UserOutlined, LockFilled, EyeFilled, EyeInvisibleFilled, LoginOutlined, MenuOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import history from '../componentes/Contexto/history';
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
import Cookies from 'js-cookie';
import escudos from '../recursos/escudosblanco2.png';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, useHistory, withRouter, useLocation } from 'react-router-dom';
const { Header } = Layout;
const { Title, Text } = Typography;

const AppHome = (props) => {
  const history = useHistory()
  const location = useLocation();

  const [data, setData] = React.useState({
    tramites: []
  });
  const [loading, setLoading] = React.useState(true);
  const [colorDark, setColorDark] = React.useState(false)

  useEffect(() => {

    getTramites()
    setColorDark(ColorLuminance(location.state.color, -0.2))

  }, []);

  function ColorLuminance(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  const getTramites = async () => {
    const URL = `${API_URL}/getSubMenusTramites`;
    try {

      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          idTramite: location.state.idTramite
        }),

        headers: { "Content-Type": "application/json" }
      });

      const resp = await response.json();
     // alert(resp)

      if (resp.msg == "200") {
        const datos = resp.respuesta;
        setData({
          ...data,
          tramites: datos
        });
      } else if (resp.msg == "300") {

      } else if (resp.msg == "400") {
        alert("algo salió mal")
      }

    } catch (error) {

    }

  }

  return (
    <div style={{width:'100vw',height:'100vh',backgroundColor:location.state.color}}>
      {/*position:'fixed',top:0,width:'100%'*/}



      <Header className="site-layout-background" style={{ padding: 0, backgroundColor: location.state.color, height: 180, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button icon={<ArrowLeftOutlined style={{ fontSize: 20, color: 'white' }} />} style={{ backgroundColor: location.state.color, borderColor: location.state.color, height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
        <div style={{ height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: location.state.color, width: '100%', display: 'flex', marginLeft: -20 }}>
          <img src={`${API_URL}/getImagen/homeBlanco/${CLAVE_MUNICIPIO}`} style={{ height: '50%', objectFit: 'contain' }} />
        </div>
      </Header>

      <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: location.state.color }}>

        <div style={{ marginTop: 0 }}></div>


        {data.tramites.map((ele, index) => {

          if ((index % 2) === 0) {

            return (
              <Link
                //  to="/Dashboard/Reporte" 
                to={{ pathname: "/Dashboard/Reporte", state: { idUsuario: location.state.idUsuario, idTramite: ele.id, nombreTramite: ele.tramite, regresoContador: location.state.regresoContador + 1, descripcion: ele.descripcion, geolocalizacion: ele.geolocalizacion, tipo: 'submenu', idDepartamento: ele.id_departamento,color:location.state.color } }}
                key={ele.id} style={{ borderColor: '#D8D8D8', textAlign: 'center', alignItems: 'center', width: '100%', height: 140, justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundColor: colorDark }}>
                <img src={ele.iconoBlanco} style={{ width: '15%' }} />
                <Text style={{ color: 'white' }}>{ele.tramite}</Text>
              </Link>
            )

          } else {

            return (
              <Link
                //  to="/Dashboard/Reporte" 
                to={{ pathname: "/Dashboard/Reporte", state: { idUsuario: location.state.idUsuario, idTramite: ele.id, nombreTramite: ele.tramite, regresoContador: location.state.regresoContador + 1, descripcion: ele.descripcion, geolocalizacion: ele.geolocalizacion, tipo: 'submenu', idDepartamento: ele.id_departamento,color:location.state.color } }}
                key={ele.id} style={{ backgroundColor:'white',borderColor: 'white',  textAlign: 'center', alignItems: 'center', width: '100%', height: 140, justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                <img src={ele.icono} style={{ width: '15%' }} />
                <Text>{ele.tramite}</Text>
              </Link>
            )

          }

        })}


      </Row>
    </div>
  );
}

export default AppHome;