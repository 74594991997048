import React from "react";
import ReactDOM from "react-dom";

import { Layout, Menu, Avatar, Dropdown, Button, Space, Badge, Anchor, Drawer,  } from "antd";
import {
  UserOutlined,
   AppstoreOutlined,
   ProjectOutlined,
   UnorderedListOutlined,
   GroupOutlined,
   InfoCircleOutlined,
     BulbOutlined,
     SettingOutlined,
HomeOutlined,
FolderOpenOutlined,
SyncOutlined,
CommentOutlined,
DeploymentUnitOutlined
} from "@ant-design/icons";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';


const { Header, Sider, Content } = Layout;
const {SubMenu} = Menu;
const MenuPrincipal = (props) =>{
    return (
      <Menu theme='dark' mode="inline" defaultSelectedKeys={["1"]} className="menu-lateral">
      <Menu.Item key="1"  icon={<HomeOutlined />} style={{color:'white'}} className='menu-item'>
      Inicio 
      <Link to="/Dashboard/Inicio" /> 
      </Menu.Item>
      <Menu.Item key="2"  icon={<FolderOpenOutlined/>} style={{color:'white'}} className='menu-item'>
      Usuarios 
      <Link to="/Dashboard/Usuarios" /> 
      </Menu.Item>

      <SubMenu icon={<CommentOutlined/>} style={{color:'white'}} title="Pensamiento Est.">
                            <Menu.Item style={{color:'white'}} key="5">
                              Largo plazo 
                              <Link to="/Dashboard/Pensamiento" />
                            </Menu.Item>
                            <Menu.Item style={{color:'white'}} key="6">
                              Mediano plazo
                              <Link to="/Dashboard/Pensamiento2" />
                              </Menu.Item>
                              <Menu.Item key="Banco" style={{color:'white'}} className='menu-item'>
                            Banco de ideas
                            <Link to="/Dashboard/Banco" />
                            </Menu.Item>   
        </SubMenu>
        <SubMenu icon={<DeploymentUnitOutlined/>} style={{color:'white'}} title="Planeación Est.">
                          <Menu.Item key="Planeacion"  style={{color:'white'}} className='menu-item'>
                            Planeación Est.
                            <Link to="/Dashboard/Planeacion" />
                            </Menu.Item>
                            <Menu.Item key="Trimestral" style={{color:'white'}} className='menu-item'>
                            Trimestrales
                            <Link to="/Dashboard/Trimestrales" />
                            </Menu.Item>
         </SubMenu>

      <Menu.Item key="Empresa" icon={ <GroupOutlined/>} style={{color:'white'}} className='menu-item'>
      Empresa
      <Link to="/Dashboard/Empresa" />
      </Menu.Item>
      <Menu.Item key="7" icon={ <InfoCircleOutlined/>} style={{color:'white'}} className='menu-item'>
      Cuenta
      <Link to="/Dashboard/Cuenta" />
      </Menu.Item>
  
</Menu>
    )
}

export default MenuPrincipal;