import React, { useContext, useState, useEffect, useRef } from 'react';
import {Row, Col,Card,Button, Modal, Form, Input,List, Slider,Select,Divider,DatePicker,Space } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined
  } from "@ant-design/icons";
  import { API_URL, } from '../WebServices/Variables'

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const ModalAddPrioridadTrimestre = ({ visible, onCreate, onCancel, titulo  }) => {
    const [form] = Form.useForm();

    return (
      <Modal
        visible={visible}
        title={titulo}
        okText="Guardar"
        okButtonProps={{
            style: {
              borderColor: "#2a3b52",
              backgroundColor:'#2a3b52'
            },
          }}
        cancelButtonProps={{
            style: {
                borderColor: "#2a3b52",
                backgroundColor:'white',
                color:'2a3b52',
                '&:hover': { color: '#2a3b52 !important' },
            },
        }}
        cancelText="Cancelar"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
       
          initialValues={{
            modifier: 'public',
          }}
        >

            <Form.Item
            name="descripcion"
          //  label="Descripción:"
            rules={[{ required: true, message: 'Ingresa una descripción!' }]}
          >

            <TextArea rows={3} placeholder="Descripción"/>
          </Form.Item>

        </Form>
      </Modal>
    );

}

export default ModalAddPrioridadTrimestre;