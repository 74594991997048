import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, } from '../componentes/WebServices/Variables';

const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
  const history = useHistory()

  useEffect(() => {


  }, [])

  return (
    <>
      {/*position:'fixed',top:0,width:'100%'*/}
      <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
        <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
        <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Soporte</Text>
      </Header>


      <div style={{ width: '100vw', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>

       <div style={{ width: '95%',marginTop:10}}>
        <Text style={{ color: 'grey', marginLeft: 20,float:'left',fontSize:18 }}>Número de soporte:</Text>
        </div>

        <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent:'center',textAlign: 'center'}}>
          <Button
            type="primary" icon={<PhoneFilled />} size='large' className="login-form-button" style={{width:'100%' , marginTop: 10, backgroundColor: 'black', borderColor: 'black' }}>
            4432636885
          </Button>
        </div>

      </div>


    </>
  )
}

export default Primero;