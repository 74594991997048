import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, EditFilled, FacebookFilled,QrcodeOutlined } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, } from '../componentes/WebServices/Variables';
const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
  const history = useHistory()
  const location = useLocation();
  const [data, setData] = React.useState({
    correo: '',
    paterno: '',
    materno: '',
    nombre: '',
    celular: '',
    nacimiento: '',
    tipoRegistro: 'Normal',
    urlFoto: '',
    idUsuario: '',
    correoUsuario: ''
  });

  useEffect(() => {
    const { idUsuario, correoUsuario } = location.state;


    getDatos(idUsuario, correoUsuario)
    return () => {

    };

  }, []);


  const getDatos = async (idUsuario, correoUsuario) => {

    // let idUsuario = await AsyncStorage.getItem('idUsuario');

    const URL = `${API_URL}/getDatosPerfil`;
    try {

      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          idUsuario: idUsuario
        }),

        headers: { "Content-Type": "application/json" }
      });

      const resp = await response.json();

      if (resp.msg == "200") {
        const datos = resp.respuesta;

        console.log(datos[0].tipo_sesion)

        setData({
          ...data,
          correo: datos[0].correo,
          paterno: datos[0].apellido_paterno,
          materno: datos[0].apellido_materno,
          nombre: datos[0].nombre,
          celular: datos[0].celular,
          nacimiento: datos[0].fecha_nacimiento,
          tipoRegistro: datos[0].tipo_sesion,
          urlFoto: datos[0].url_foto,
          idUsuario: idUsuario,
          correoUsuario: correoUsuario
        });

      } else if (resp.msg == "300") {

      } else if (resp.msg == "400") {
        alert("algo salió mal")
      }

    } catch (error) {

    }
  }

  return (
    <>
      {/*position:'fixed',top:0,width:'100%'*/}
      <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
        <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
        <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Perfil</Text>
      </Header>

      <div style={{ width: '100vw', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <Avatar size={110} icon={<UserOutlined />} src={data.urlFoto} />
        </div>

        <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'row', marginTop: 40 }}>
          <Text style={{ float: 'left', color: 'grey' }}>Nombre:</Text>
          <Text style={{ float: 'right', color: 'grey' }}>{data.nombre} {data.paterno} {data.materno}</Text>
        </div>

        <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'row', marginTop: 10 }}>
          <Text style={{ float: 'left', color: 'grey' }}>Correo:</Text>
          <Text style={{ float: 'right', color: 'grey' }}>{data.correo}</Text>
        </div>

        <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'row', marginTop: 10 }}>
          <Text style={{ float: 'left', color: 'grey' }}>Fecha de nacimiento:</Text>
          <Text style={{ float: 'right', color: 'grey' }}>{data.nacimiento}</Text>
        </div>

        <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'row', marginTop: 10 }}>
          <Text style={{ float: 'left', color: 'grey' }}>Celular:</Text>
          <Text style={{ float: 'right', color: 'grey' }}>{data.celular}</Text>
        </div>

        <Link
          //to={`/Dashboard/EditarPerfil/${data.correo}/${data.nombre}/${data.paterno}/${data.materno}/${data.celular}/${data.nacimiento}/${data.urlFoto}`} 
          to={{ pathname: "/Dashboard/EditarPerfil", state: { nombre: data.nombre, paterno: data.paterno, materno: data.materno, correo: data.correo, nacimiento: data.nacimiento, celular: data.celular, urlFoto: data.urlFoto, idUsuario: data.idUsuario } }}
          //params={{ nombre:data.nombre,paterno:data.paterno,materno:data.materno,correo:data.correo,nacimiento:data.nacimiento,celular:data.celular }} 
          style={{ width: '100%' }}>
          <Button icon={<EditFilled />} size='large' className="login-form-button" style={{ color: 'grey', width: '90%', backgroundColor: 'white', borderColor: 'grey', marginTop: 15 }}>
            Editar perfil
          </Button>
        </Link>

        <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'row', marginTop: 0 }}>
          <Divider plain />
        </div>

        <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column' }}>
          <Text style={{ float: 'left', color: 'grey' }}>Inicio de sesión</Text>
        </div>


        {data.tipoRegistro == "Normal" ?
          <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column' }}>
            <div style={{ backgroundColor: 'grey', width: 30, height: 30, borderRadius: 15, }}>
              <UserOutlined style={{ color: 'white', marginTop: 8 }} />
            </div>
          </div>
          :
          <>
            {data.tipoRegistro == "Google" ?
              <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column' }}>
                <div style={{ backgroundColor: '#db4a39', width: 30, height: 30, borderRadius: 15, }}>
                  <GoogleOutlined style={{ color: 'white', marginTop: 8 }} />
                </div>
              </div>
              :
              <>
                {data.tipoRegistro == "Llavero" ?
                 <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column' }}>
                 <div style={{ backgroundColor: '#F3F3F3', width: 30, height: 30, borderRadius: 15, }}>
                   <QrcodeOutlined style={{ color: 'black', marginTop: 8 }} />
                 </div>
               </div>
                  :
                  <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column' }}>
                    <div style={{ backgroundColor: '#3b5998', width: 30, height: 30, borderRadius: 15, }}>
                      <FacebookFilled style={{ color: 'white', marginTop: 8 }} />
                    </div>
                  </div>
                }
              </>



            }
          </>

        }




      </div>


    </>
  )
}

export default Primero;