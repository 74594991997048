import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled, SendOutlined, StarFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory,useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import Cargando from '../componentes/Utilidades/Cargando'
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
const { Header } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;

const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();

    const [cargando, setCargando] = React.useState(false)
    const [estrellas, setEstrellas] = React.useState(0);

    const [data, setData] = React.useState({
        queja:''
    });

    useEffect(() => {
   

        return () => {

        };

    }, []);

 
    const enviar = async () => {
        if (data.queja == '') {
            alert('Para enviar una segurencia de mejora a la app, llene el campo.')
        } else {
            setCargando(true)

            const URL = `${API_URL}/enviarQuejaMunicipios`;
            try {

                const response = await fetch(URL, {
                    method: 'POST',
                    body: JSON.stringify({
                        queja: data.queja,
                        idUsuario: location.state.idUsuario,
                        claveMunicipio:CLAVE_MUNICIPIO
                    }),

                    headers: { "Content-Type": "application/json" }
                });

                const resp = await response.json();
                console.log(resp)

                if (resp.msg == "200") {
                    setTimeout(() => {
                        setCargando(false)
                        setData({ ...data, queja:'' })
                        alert("Mejora a la app enviada con exito!")
                    }, 2000);

                } else if (resp.msg == "300") {
                    setTimeout(() => {
                        setCargando(false)
                    }, 2000);
                }
                else if (resp.msg == "400") {
                    setTimeout(() => {
                        setCargando(false)
                        alert("algo salio mal")
                    }, 2000);

                }

            } catch (error) {
                setCargando(false)
            }
        }

    }

    return (
        <>
        <Cargando visible={cargando} />
            {/*position:'fixed',top:0,width:'100%'*/}
            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Mejoras a la app</Text>
            </Header>

            <div style={{ width: '100vw', justifyContent: 'centeer', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
          
                <div style={{ width: '90%', marginTop: 10 }}>
                    <Text style={{ color: 'black' }}>Envianos tu sugerencia de mejora de la app.</Text>
                </div>


                <div style={{ width: '80%', marginTop: 20 }}>
                    <TextArea value={data.queja}
                    onChange={(val) => {
                       setData({...data,queja:val.target.value})
                    }}
                    rows={2} placeholder="Sugerencia de mejora" style={{ width: '100%', borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', borderRadius: 0 }} />
                </div>


                <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: 30 }}>
                    <Button
                        type="primary" icon={<SendOutlined />} size='large' onClick={()=>enviar()} className="login-form-button" style={{ width: '100%', marginTop: 10, backgroundColor: props.colorPrimary, borderColor: props.colorPrimary }}>
                        Enviar 
                    </Button>
                </div>

            </div>


        </>
    )
}

export default Primero;