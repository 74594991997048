import React, { useEffect, useState,useRef } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowRightOutlined, PhoneFilled, CloseOutlined, ThunderboltFilled, CameraOutlined, DeleteFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
import '../componentes/Camara/homeStyle.css'
import { WebcamCapture } from '../componentes/Camara/Webcam'
import Webcam from "react-webcam";
import ModalDescripcion from '../componentes/Utilidades/ModalDescripcion'
import { browserName, browserVersion,isAndroid,isMobile } from "react-device-detect";
import Cam, { DEVICE, FACING_MODE, PLACEMENT } from 'react-camera-ios';
//import {Camera} from "react-camera-pro";

const { Header } = Layout;
const { Title, Text } = Typography;

const videoConstraints = {
    facingMode: { exact: "environment" }
  };

const Home = (props) => {
    const history = useHistory()
    const location = useLocation();

    const [isMobile, setIsMobile] = useState(false);

    const [visibleMenu2, setVisibleMenu2] = React.useState(false);

    const [data, setData] = React.useState({
        fotos: [],
        fotosBase: [],
    });


    const [image, setImage] = useState('');
    const webcamRef = React.useRef(null);
    const camera = useRef(null);

    const [deviceId, setDeviceId] = React.useState('');
    const [devices, setDevices] = React.useState([]);

    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');



    /*useEffect(() => {


        //  navigator.mediaDevices.enumerateDevices().then(handleDevices);
        navigator.mediaDevices.enumerateDevices().then(function (mediaDevices) {
            for (var i = 0; i < mediaDevices.length; i++) {
                if (String(mediaDevices[i].label).includes("back") || String(mediaDevices[i].label).includes("trasera")) {
                    setDeviceId(mediaDevices[i].deviceId)
                }
            }
        }).catch(function (err) {
            console.log(err)
        });

        // check()

        return () => {

        };

    }, []);*/

    const check = async () => {
        let stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: deviceId } });
        let { width, height } = stream.getTracks()[0].getSettings();
        setWidth(width)
        setHeight(height)
        alert(`${width}x${height}`)
        console.log(`${width}x${height}`);
    }

    const flash = async () => {
        let stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: deviceId } });
        const track = stream.getTracks()[0];
        track.applyConstraints({
            advanced: [{ torch: true }]
        });
        /*  navigator.mediaDevices.getUserMedia({
              video: {
                  deviceId: deviceId,
                  // facingMode: ['user', 'environment'],
                  //  height: {ideal: 1080},
                  // width: {ideal: 1920}
              }
          }).then(function (stream) {
              alert(stream)
              //const track = stream.getVideoTracks()[0];
  
             
  
          }).catch(function (err) {
              alert(err)
          });*/



    }

    const handleDevices = React.useCallback(
        mediaDevices => {
          // alert(JSON.stringify(mediaDevices)+" "+navigator.userAgent)

            for (var i = 0; i < mediaDevices.length; i++) {
                if (String(mediaDevices[i].label).includes("back") || String(mediaDevices[i].label).includes("trasera")) {
                    setDeviceId(mediaDevices[i].deviceId)
                    setIsMobile(true)
                }
                //alert(JSON.stringify(mediaDevices[i].label))
            }


            // setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"))
        },

        [setDevices]
    );


    React.useEffect(
        async () => {

    
        /* if(isAndroid){
            if ((browserName == "Mobile Safari" && parseInt(browserVersion) > 11) || (browserName == "Chrome" && parseInt(browserVersion) > 70)) {

                await navigator.getUserMedia({ video: true }, function (stream) {
                    navigator.mediaDevices.enumerateDevices().then(handleDevices);
                }, err => alert(err));
        
        } else {
            history.goBack()
            alert("Para usar la camara es necesario actualizar tu navegador")
           } 
         }*/
            


        },
        [handleDevices]
    );

    const capture = React.useCallback(
        () => {
 
            if (data.fotos.length >= 3) {
                alert("El maximo permitido de fotos son 3.")
            }else{

             //  if(isMobile){
                try{
             //   if (camera.current.takePhoto() == true || camera.current.takePhoto() != null ||  camera.current.takePhoto() != undefined) {
                   // const imageSrc = camera.current.takePhoto();

                   if (camera.current.getScreenshot() == true || camera.current.getScreenshot() != null ||  camera.current.getScreenshot() != undefined) {
                    const imageSrc = camera.current.getScreenshot()

                   // alert(imageSrc)
                    setImage(imageSrc)
                    setData({
                        ...data,
                        fotos: [...data.fotos, { img: imageSrc }],
                        fotosBase: [...data.fotosBase, { img: imageSrc.split(',')[1], url: imageSrc }]
                    });
    
                } else {
    
                }
            }catch(e){
                     
            }
        /*       }else{
                   alert("Esta función está disponible en dispositivos moviles")
               }*/
                
            }


        });


    const size = useWindowSize();
    const isLandscape = size.height <= size.width;
    const ratio = isLandscape ? size.width / size.height : size.height /
        size.width;


    const eliminarFoto = (fotoEliminar) => {
        /* const nuevas = data.fotos.filter((foto) => foto !== fotoEliminar);
         const nuevasBase = data.fotosBase.filter((foto) => foto !== fotoEliminar.split(',')[1]);
         setData({
             ...data,
             fotos: nuevas,
             fotosBase:nuevasBase
         });*/

        const list = data.fotos;
        list.splice(data.fotos.indexOf(fotoEliminar), 1);

        var deleted = data.fotosBase.filter((item) => item.url !== fotoEliminar.img)

        setData({
            ...data,
            fotos: list,
            fotosBase: deleted
        });

    }

    const onDesc = (val) => {
        var descripcion = val.descripcion;
        setVisibleMenu2(false)

        if (location.state.geolocalizacion == 0) {

            if (navigator.onLine) {
                //   checkValidacion(descripcion)
            } else {
                // enviarLocal(descripcion)
            }

        } else {

            if(data.fotos.length>0){
                   
                history.push({
                    pathname: '/Dashboard/ConfirmarUbicacion',
                    state: { idUsuario: location.state.idUsuario, fotos: data.fotos, fotosBase: data.fotosBase, idTramite: location.state.idTramite, nombreTramite: location.state.nombreTramite, regresoContador: location.state.regresoContador + 1, descripcion: descripcion, tipo: location.state.tipo, idDepartamento: location.state.idDepartamento },
                });

              }else{
               alert('Para continuar es necesario tomar al menos una foto..', [
                  {
                    text: 'Entendido', onPress: () => {
          
                    }
                  },
                ]);
              }

           

        }

    }

    const confirmarUbi = () => {
        // alert(location.state.descripcion+" "+location.state.geolocalizacion)

        if (location.state.descripcion == 0) {
            if (location.state.geolocalizacion == 0) {
                // enviarServer('')
            } else {

              //  alert(data.fotos.length)
                if(data.fotos.length>0){
                   
                    history.push({
                        pathname: '/Dashboard/ConfirmarUbicacion',
                        state: { idUsuario: location.state.idUsuario, fotos: data.fotos, fotosBase: data.fotosBase, idTramite: location.state.idTramite, nombreTramite: location.state.nombreTramite, regresoContador: location.state.regresoContador + 1, descripcion: '', tipo: location.state.tipo, idDepartamento: location.state.idDepartamento },
                    });
    
                  }else{
                   alert( 'Para continuar es necesario tomar al menos una foto.', [
                      {
                        text: 'Entendido', onPress: () => {
              
                        }
                      },
                    ]);
                  }

               
            }

        } else { //antes de ir al mapa se pide descripcion delm reporte


            /* history.push({
                 pathname: '/Dashboard/ConfirmarUbicacion',
                 state: { idUsuario:location.state.idUsuario,fotos: data.fotos, fotosBase: data.fotosBase, idTramite: location.state.idTramite, nombreTramite: location.state.nombreTramite, regresoContador: location.state.regresoContador + 1, descripcion: '', tipo: location.state.tipo, idDepartamento: location.state.idDepartamento },
             });**/

            setVisibleMenu2(true)
        }
    }


    return (
        <>
            <Header className="site-layout-background" style={{ padding: 0, width: '100vw', backgroundColor: 'transparent', position: 'absolute', top: 0, zIndex: 10 }}>
                <Button icon={<CloseOutlined style={{ fontSize: 20, color: 'white' }} />} style={{ backgroundColor: 'transparent', borderColor: 'transparent', height: '100%', float: 'left', marginLeft: '20px' }} onClick={() => history.goBack()}></Button>
                <Button icon={<ThunderboltFilled style={{ fontSize: 20, color: 'white' }} />} style={{ backgroundColor: 'transparent', borderColor: 'transparent', height: '100%', float: 'right', marginRight: '20px' }} onClick={() => flash()}></Button>
            </Header>

       {/*     <Cam
                                    device={DEVICE.MOBILE}
                                    facingMode={FACING_MODE.ENVIRONMENT}
                                    placement={PLACEMENT.COVER}
                                    quality="1"
                                    onError={error => console.log(error)}
                                    onTakePhoto={dataUrl => console.log(dataUrl)}
       />*/}



           <div style={{ width: '100vw', height: '100vh' }}>
                <div style={{ position: 'relative', backgroundColor: 'black', width: '100vw', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80%' }}>

           {/*  <Camera ref={camera} facingMode='environment'/>*/}
           <Webcam
        audio={false}
        ref={camera}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        style={{height: '100%',width: '100%'}}
      />
        
                    <div style={{ borderWidth: 4, borderColor: 'white', position: 'absolute', bottom: 10, zIndex: 20, width: 144, height: 144, borderRadius: 72, alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white' }}>
                        



                        <div onClick={() => { capture() }} style={{ position: 'absolute', bottom: 6, zIndex: 20, width: 132, height: 132, borderRadius: 65.5, alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: location.state.color }}>   
                         <CameraOutlined style={{ fontSize: 25, color: 'white' }} />
                        </div>

                    </div>


                </div>

                <div style={{ width: '100vw', height: '20%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '80%', height: '100%', }}>

                        <div className="outer" style={{ height: '100%', width: '100%' }}>
                            {data.fotos.map(ele => {
                                return (
                                    <div style={{ width:100, height: '100%', padding: 5, position: 'relative' }} className="inner">
                                        <img src={ele.img} style={{ width: '100%', height: '100%' }} />
                                        <DeleteFilled onClick={() => eliminarFoto(ele)} style={{ fontSize: 27, color: 'white', position: 'absolute', top: 5, right: 5 }} />
                                    </div>
                                )
                            })}
                        </div>


                    </div>
                    <div style={{ width: '20%', height: '100%', backgroundColor:location.state.color, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ArrowRightOutlined onClick={() => { confirmarUbi() }} style={{ fontSize: 30, color: 'white' }} />

                    </div>
                </div>

            </div>



            <ModalDescripcion visible={visibleMenu2}
                onCreate={(e) => onDesc(e)}
                onCancel={() => {
                    setVisibleMenu2(false)
                }}
                titulo="Agrega una descripción"
            />

        </>
    )
}


function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        //  window.addEventListener("resize", handleResize);


        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => { }//window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default Home