import React from "react";
import ReactDOM from "react-dom";

import { Layout, Menu, Avatar, Dropdown, Button, Space, Badge, Anchor, Drawer,  } from "antd";
import {
  UserOutlined,
   AppstoreOutlined,
   ProjectOutlined,
   UnorderedListOutlined,
   GroupOutlined,
   InfoCircleOutlined,
     BulbOutlined,
     SettingOutlined,
HomeOutlined,
TeamOutlined
} from "@ant-design/icons";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';

const { Header, Sider, Content } = Layout;
const {SubMenu} = Menu;
const MenuPrincipal = (props) =>{
    return (
      <Menu theme='dark' mode="inline" defaultSelectedKeys={[props.ultimaRuta]} className="menu-lateral">
      <Menu.Item key="Inicio"  icon={<HomeOutlined />} style={{color:'white'}} className='menu-item'>
      Inicio 
      </Menu.Item>
      <Menu.Item key="ListaEmpresas" icon={ <GroupOutlined/>} style={{color:'white'}} className='menu-item'>
      Empresas
      <Link to="/Dashboard/ListaEmpresas" />
      </Menu.Item>

      <Menu.Item key="Usuarios"  icon={<UserOutlined />} style={{color:'white'}} className='menu-item'>
                            Usuarios   
                            <Link to="/Dashboard/Usuarios" /> 
                            </Menu.Item>
      <Menu.Item key="Consultores" icon={ <TeamOutlined/>} style={{color:'white'}} className='menu-item'>
      Consultores
      <Link to="/Dashboard/Consultores" />
      </Menu.Item>
     
  
</Menu>
    )
}

export default MenuPrincipal;