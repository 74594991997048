import React from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled, SendOutlined, StarFilled,SaveFilled } from '@ant-design/icons';
const { Title, Text } = Typography;
const Cargando = ({visible,titulo,subtitulo,irBorradores,entendido}) => {

    return (
      <Modal
        visible={visible}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={null}
        closable={false}
        keyboard={false}
        style={{justifyContent:'center',alignItems:'center',textAlign:'center',maxWidth:350}}
      >
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column', marginTop: 30 }}>
        <div style={{ width: '90%', marginTop: -20,textAlign:'left' }}>
              <Text style={{ color: 'black', fontSize: 22 }}>{titulo}</Text>
      </div>

      <div style={{ width: '90%', marginTop: 10,textAlign:'left' }}>
              <Text style={{ color: 'black', fontSize: 17 }}>{subtitulo}</Text>
      </div>

      <div style={{ width: '90%', marginTop: 10,textAlign:'left' }}>
           <Button type="text" onClick={()=>{irBorradores()}} icon={<SaveFilled style={{marginRight:10}}/>}  className="login-form-button" style={{  marginLeft:-20,marginTop:10,textAlign:'left',width: '100%',backgroundColor:'white', borderColor: 'white',color:'black',fontSize:16}}>
                Ir a borradores
              </Button>
      </div>
     <Divider style={{width:'100%'}}/>

      <div style={{ width: '100%',textAlign:'center',marginTop:-15, }}>
           <Button type="text" onClick={()=>{entendido()}}  className="login-form-button" style={{ textAlign:'center',width: '100%',backgroundColor:'white',borderColor: 'white',color:'black',fontSize:16}}>
               Entendido
              </Button>
      </div>
    
      </div>

      </Modal>
    );

}

export default Cargando;