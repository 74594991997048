import React from 'react';
import { Modal,Spin } from 'antd';

const Cargando = ({visible}) => {

    return (
      <Modal
        visible={visible}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={null}
        closable={false}
        keyboard={false}
        style={{justifyContent:'center',alignItems:'center',textAlign:'center',maxWidth:100}}
      >
       <Spin size="large" style={{alignSelf:'center'}}/>
      </Modal>
    );

}

export default Cargando;