import React,{useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import Home from '../../paginas/Home';
import Perfil from '../../paginas/Perfil';
import Configuracion from '../../paginas/Configuracion';
import SoporteApp from '../../paginas/SoporteApp';
import AcercaDe from '../../paginas/AcercaDe';
import MisReportes from '../../paginas/MisReportes';
import Calificanos from '../../paginas/Calificanos';
import Reporte from '../../paginas/Reporte';
import Ayuda from '../../paginas/Ayuda';
import Directorio from '../../paginas/Directorio';
import Borradores from '../../paginas/Borradores';
import EditarPerfil from '../../paginas/EditarPerfil';
import SubMenus from '../../paginas/SubMenus';
import ConfirmarUbicacion from '../../paginas/ConfirmarUbicacion';
import ValidarCuenta from '../../paginas/ValidarCuenta';
import ValidarCuentaBorradores from '../../paginas/ValidarCuentaBorradores';
import QuejasSugerencias from '../../paginas/QuejasSugerencias';
import ApoyosSociales from '../../paginas/ApoyosSociales';
import MiLlavero from '../../paginas/MiLlavero';


const Rutas = (props) =>{

  useEffect(() => {
   
    console.log(props)

  return () => {};
},[]);


    return (
        <Switch>
       

            <Route
              exact
              path="/Dashboard"
              render={() => {
                    return (
                      <Redirect to={"/Dashboard/Inicio"}/> 
                    )
                }}
            />
            <Route path="/Dashboard/Inicio" component={()=><Home tramites={props.tramites} abrirMenu={props.abrirMenu} idUsuario={props.idUsuario}/>}/>
            <Route path="/Dashboard/Perfil" component={()=><Perfil/>}/>
            <Route path="/Dashboard/Configuracion" component={()=><Configuracion/>}/>
            <Route path="/Dashboard/SoporteApp" component={()=><SoporteApp/>}/>
            <Route path="/Dashboard/AcercaDe" component={()=><AcercaDe/>}/>
            <Route path="/Dashboard/Calificanos" component={()=><Calificanos colorPrimary={props.colorPrimary}/>}/>
            <Route path="/Dashboard/Reporte"><Reporte colorPrimary={props.colorPrimary}/></Route>
            <Route path="/Dashboard/Ayuda" component={Ayuda}/>
            <Route path="/Dashboard/Directorio" component={Directorio}/>
            <Route path="/Dashboard/Borradores" component={()=><Borradores colorPrimary={props.colorPrimary}/>}/>
           <Route path="/Dashboard/EditarPerfil" component={()=><EditarPerfil colorPrimary={props.colorPrimary}/>}/>
           <Route path="/Dashboard/MisReportes" component={MisReportes}/>
           <Route path="/Dashboard/SubMenus" component={SubMenus}/>
           <Route path="/Dashboard/ConfirmarUbicacion" component={ConfirmarUbicacion}/>
           <Route path="/Dashboard/ValidarCuenta" component={ValidarCuenta}/>
           <Route path="/Dashboard/ValidarCuentaBorradores" component={ValidarCuentaBorradores}/>
           <Route path="/Dashboard/QuejasSugerencias" component={()=><QuejasSugerencias colorPrimary={props.colorPrimary}/>}/>
           <Route path="/Dashboard/ApoyosSociales" component={()=><ApoyosSociales colorPrimary={props.colorPrimary}/>}/>
           <Route path="/Dashboard/MiLlavero" component={()=><MiLlavero colorPrimary={props.colorPrimary}/>}/>
           
           
        </Switch>
      );
}

export default Rutas;
