import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, EditFilled, InfoCircleOutlined, RightOutlined,RobotFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL,APPWEB_VERSION } from '../componentes/WebServices/Variables';
const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
  const history = useHistory()

  useEffect(() => {


  }, [])

  return (
    <>
     {/*position:'fixed',top:0,width:'100%'*/}
     <Header className="site-layout-background" style={{ padding: 0,backgroundColor:'white'}}>
        <Button icon={<ArrowLeftOutlined style={{fontSize: 20}}/>} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px'}} onClick={() =>history.goBack()}></Button>
        <Text style={{ fontSize: 20, marginLeft: 15,float:'left',marginTop:-2 }}>Acerca de</Text>
      </Header>

      <div style={{ width: '100vw',  justifyContent: 'centeer', alignItems:'flex-start', display: 'flex', flexDirection: 'column' }}>
      <Text style={{ color:'grey',marginLeft:20}}>Desarrollador: Empatica Soluciones</Text>
      <Text style={{ color:'grey',marginLeft:20}}>Versión de la AppWEB: {APPWEB_VERSION}</Text>

      <Text style={{ color:'grey',marginLeft:20,marginTop:10}}>{'\u00A9'}Todos los derechos reservados</Text>
      </div>
           


    </>
  )
}

export default Primero;