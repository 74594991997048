import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled, SendOutlined, StarFilled,QrcodeOutlined } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, } from '../componentes/WebServices/Variables';
import Playstore from '../recursos/playstore.png';
import Appstore from '../recursos/appstore.png';
const { Header } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();

    const [llaveroVinculado, setLlaveroVinculado] = React.useState(false)

    const [ID, setID] = React.useState('')
    const [QR, setQR] = React.useState('')
    const [CONSE, setCONSE] = React.useState('')
    const [CCV, setCCV] = React.useState('')
    const [load, setLoad] = React.useState(false);

    const [cargando, setCargando] = React.useState(false)

    const [isMobile, setIsMobile] = useState(true);
    const [deviceType, setDeviceType] = useState("");
    const [isTipoLLavero, setIsTipoLLavero] = React.useState(false);

    const [data, setData] = React.useState({
        comentario: '',
        idUsuario: '',
        correoUsuario: ''
    });

    useEffect(() => {
        const { idUsuario, correoUsuario } = location.state;
        setData({
            ...data,
            idUsuario: idUsuario,
            correoUsuario: correoUsuario
        });


        console.log(navigator.userAgent)

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)
        ) {

            setIsMobile(true)
            if (/iPhone|iPad/i.test(navigator.userAgent)) {
                setDeviceType("IOS");
            } else if (/Android/i.test(navigator.userAgent)) {
                setDeviceType("ANDROID");
            }


        } else {
            setIsMobile(false)
        }


        /*if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)
          ) {
            setDeviceType("Mobile");
          } else {
            setDeviceType("Desktop");
          }*/

        check(idUsuario)

        return () => {

        };

    }, []);

    const check = async (idUsuario) => {

        //let idUsuario = await AsyncStorage.getItem('idUsuario');
        const URL = `${API_URL}/checkIsLlaveroVinculado`;
        try {
            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    idUsuario: idUsuario,
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                setIsTipoLLavero(false)
                if (resp.respuesta == '1') {
                    setLlaveroVinculado(true)

                    setID(resp.datos[0].ID.replace("\n", "").trim())
                    setQR(`${API_URL}/getLlaveroQr/112/qr_${resp.datos[0].ID.replace("\n", "").trim()}.jpg`)
                    setCONSE(resp.datos[0].CONSE)
                    setCCV(resp.datos[0].CCV)

                } else {
                    setLlaveroVinculado(false)
                }
                // console.log(resp.datos)

                setLoad(true)



            } else if (resp.msg == "250") {
                setIsTipoLLavero(true)
                setLlaveroVinculado(true)
                setLoad(true)
                /* setID(resp.datos[0].ID.replace("\n", "").trim())
                     setQR(`${API_URL}/getLlaveroQr/112/qr_${resp.datos[0].ID.replace("\n", "").trim()}.jpg`)
                     setCONSE(resp.datos[0].CONSE)
                     setCCV(resp.datos[0].CCV)
                     setLoad(true)*/
            }
            else if (resp.msg == "400") {

            }

           /* if (resp.msg == "200") {
       

                if (resp.respuesta == '1') {
                    setLlaveroVinculado(true)

                    setID(resp.datos[0].ID.replace("\n", "").trim())
                    setQR(`${API_URL}/getLlaveroQr/112/qr_${resp.datos[0].ID.replace("\n", "").trim()}.jpg`)
                    setCONSE(resp.datos[0].CONSE)
                    setCCV(resp.datos[0].CCV)
                } else {
                    setLlaveroVinculado(false)
                }
            

               

                setLoad(true)


            } else if (resp.msg == "250") {
                setLlaveroVinculado(true)
            }
            else if (resp.msg == "400") {

            }*/

        } catch (error) {

        }

    }

    const miLlavero = () =>{
        history.push({
            pathname: '/Dashboard/MiLlavero',
            state: { id: ID, qr: QR, conse: CONSE, ccv: CCV },
          });
    }


    return (
        <>
            {/*position:'fixed',top:0,width:'100%'*/}
            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}></Text>
                {llaveroVinculado ?
                <>
                {isTipoLLavero ?
                    null:
                    <Button icon={<QrcodeOutlined/>} onClick={() => {miLlavero()}} type="primary" size='large' className="login-form-button" style={{ marginTop: 10, backgroundColor: props.colorPrimary, borderColor: props.colorPrimary, float: 'right', marginRight: '12px' }}>
                        Mi llavero
                    </Button>
                    }
                    </>
                    :
                    null
                }

            </Header>

            <div style={{ width: '100vw', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '90%', marginTop: 10, textAlign: 'center' }}>
                    <Text style={{ color: 'black', fontSize: 25 }}>Apoyos Sociales</Text>
                </div>


                {!load ?
                    <div style={{ width: '90%', marginTop: 10 }}>

                    </div>
                    :
                    <>


                        {llaveroVinculado ?
                            <>

                                <div style={{ width: '90%', marginTop: 10 }}>
                                    <Text style={{ color: 'black' }}>Los apoyos pronto serán liberados.</Text>
                                </div>


                            </>

                            :

                            <div style={{ width: '80%', marginTop: 30, display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

                                <Text style={{ color: 'black', }}>Para generar mi llavero es necesario descargar la aplicación móvil.</Text>

                                {deviceType == 'IOS' ?

                                    <Link
                                         to={{ pathname: "http://itunes.apple.com/app/id1585670389" }}
                                         //target="_blank"
                                         //</div>style={{ backgroundColor: '#fff',alignSelf:'center', textAlign: 'center', alignItems: 'center', width: '80%', height: 140,borderRadius:10,borderColor:'grey',borderWidth:2, justifyContent: 'center', display: 'flex', flexDirection: 'row' }}
                                         style={{ marginTop: 10, border: '1px solid rgba(0, 0, 0, 0.05)', width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: 100 }}
                                     >
                                         <img src={Appstore} style={{ height: '50%', objectFit: 'contain' }} />
                                         <Text style={{ color: 'black', marginLeft: 10, fontWeight: 'bold' }}>ir a App Store.</Text>
                                    </Link>

                                   /* <div style={{ width: '90%', marginTop: 10 }}>
                                        <Text style={{ color: 'black' }}>Esta función estará disponible pronto.</Text>
                                    </div>*/


                                    :
                                    <Link
                                        to={{ pathname: "https://play.google.com/store/apps/details?id=com.c5ciudadano&hl=en" }}
                                        target="_blank"
                                        //</div>style={{ backgroundColor: '#fff',alignSelf:'center', textAlign: 'center', alignItems: 'center', width: '80%', height: 140,borderRadius:10,borderColor:'grey',borderWidth:2, justifyContent: 'center', display: 'flex', flexDirection: 'row' }}
                                        style={{ marginTop: 10, border: '1px solid rgba(0, 0, 0, 0.05)', width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: 100 }}
                                    >
                                        <img src={Playstore} style={{ height: '50%', objectFit: 'contain' }} />
                                        <Text style={{ color: 'black', marginLeft: 10, fontWeight: 'bold' }}>ir a Play Store.</Text>
                                    </Link>

                                }

                            </div>


                        }

                    </>
                }


            </div>


        </>
    )
}

export default Primero;