import React, { useContext, useState, useEffect, useRef } from 'react';
import {Row, Col,Card,Button, Modal, Form, Input,List, Select,Divider,DatePicker,Space } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined
  } from "@ant-design/icons";
  import { API_URL, } from '../WebServices/Variables'

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const ModalAddPrioridadTrimestre = ({ visible, onCreate, onCancel,idUsuario,rol }) => {
    const [form] = Form.useForm();

    const [data, setData] = React.useState({
        empresas:[]
     });
  
      useEffect(() => {
        if(rol=="Consultor" || rol=="ConsultorPrincipal"){
            getEmpresas();
        }

        return () => {
  
        };
          
        },[]);

      const getEmpresas = async () =>{
        
        const URL = `${API_URL}/getEmpresasPermitidas`;
    
        try {
            const response = await fetch(URL,{
                method: 'POST',
                body: JSON.stringify({
                idUsuario:idUsuario,
            }),
            headers: {"Content-Type": "application/json"}
            });
            const resp = await response.json();
            console.log(resp.respuesta)
            if(resp.msg==="200"){
                const datos = resp.respuesta;
                  console.log(datos)
                setData({
                  ...data,
                  empresas:datos,          
                });

            }else if(resp.msg==="300"){
            
            }

            }catch(error) {
              alert("Algo salió mal en la conexión con el internet "+error);
            }

       }

    return (
      <Modal
        visible={visible}
        title="Selecciona la empresa que desea ver su información"
        okText="Seleccionar"
        okButtonProps={{
            style: {
              borderColor: "#2a3b52",
              backgroundColor:'#2a3b52'
            },
          }}
        cancelButtonProps={{
            style: {
                borderColor: "#2a3b52",
                backgroundColor:'white',
                color:'2a3b52',
                '&:hover': { color: '#2a3b52 !important' },
            },
        }}
        cancelText="Cancelar"
        onCancel={()=>{
         //   form.resetFields();
            onCancel()
        }       
        }
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
           //   form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: 'public',
          }}
        >
          <Form.Item
            name="empresa"
            label="Empresa:"
            rules={[{ required: true, message: 'Selecciona una empresa!' }]}
          >
            


            <Select >

            {data.empresas.map((item,index) => {
            
            return (            
            <Option value={`${item.id}*${item.value}`}>{item.value}</Option>
            )
            })

            }

            </Select>

          </Form.Item>


        </Form>
      </Modal>
    );

}

export default ModalAddPrioridadTrimestre;