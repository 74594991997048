import React from "react";
import ReactDOM from "react-dom";
import { Layout, Menu, Avatar, Dropdown, Button, Space, Badge, Anchor, Drawer,Select , Typography,Row, Col,Modal} from "antd";
import {
  BorderOuterOutlined,
  UserOutlined,
  MenuOutlined,
   BellOutlined,
   SettingOutlined,
   SettingFilled,
   LogoutOutlined,
   ExclamationCircleOutlined 
} from "@ant-design/icons";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';


const { Header, Sider, Content } = Layout;
const {SubMenu} = Menu;
const MenuPrincipal = (props) =>{
    
    return (
        <Row style={{width:'280px',height:'290px',padding:0}}>

        <Row align='center' lg={{ span: 24 }} style={{height:'80%'}}>
      
          <Col>
          <UserOutlined style={{ fontSize: '56px', color: 'grey', borderRadius:'50%',padding:'10px',backgroundColor:'white' }}/>
          </Col>
      
        </Row>
      
        <Row justify='center' align='middle' lg={{ span: 24 }} style={{height:'20%'}}>
      
          <Col lg={{ span: 12 }} style={{height:'100%'}}>
          <Button type="text" icon={<SettingOutlined />} style={{width:'100%',height:'100%',borderTopWidth:'1px',borderTopColor:'#F0F0F0'}}>
            Configuración
          </Button>
          </Col>
      
          <Col lg={{ span: 12 }} style={{height:'100%'}}>
          <Button onClick={()=>{}} type="text" icon={<LogoutOutlined />} style={{width:'100%',height:'100%',borderTopWidth:'1px',borderTopColor:'#F0F0F0',borderLeftWidth:'1px',borderLeftColor:'#F0F0F0'}}>
            Salir
          </Button>
          </Col>
      
        </Row>
      
        </Row>
    )
}

export default MenuPrincipal;