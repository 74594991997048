import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, LockFilled, EyeFilled, EyeInvisibleFilled, LoginOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { Link, useParams, useHistory } from 'react-router-dom'
import llavero from '../recursos/llavero2.jpg';
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";
import Cargando from '../componentes/Utilidades/Cargando'
import * as Vibrant from 'node-vibrant';
import AuthApi from '../componentes/Contexto/AuthApi';
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
  //const history = useHistory()
  const Auth = React.useContext(AuthApi);
  let { id } = useParams();
  const [color, setColor] = React.useState('#000')
  const [cargando, setCargando] = React.useState(false)

  var fullColorHex = function (r, g, b) {
    var red = rgbToHex(r);
    var green = rgbToHex(g);
    var blue = rgbToHex(b);
    return red + green + blue;
  }

  var rgbToHex = function (rgb) {
    var hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  }


  useEffect(() => {

    Vibrant.from(`${API_URL}/getImagenMunicipio/${CLAVE_MUNICIPIO}/?${Date.now()}`).getPalette()
      .then(palette => {

        const paleta = palette.Vibrant._rgb;
        const el = document.querySelector("meta[name='theme-color']");
        const hexa = "#" + fullColorHex(paleta[0], paleta[1], paleta[2]);
        console.log(hexa)
        setColor(hexa)
        el.setAttribute('content', hexa)
        // do what ever you want with palette, even setState if you want to, just avoid calling it from a render/componentWillUpdate/componentDidUpdate to avoid having the same error you've got in the first place
      })
      .catch(error => {
        // handle errors
      });

  }, [])


  const entrar = async () => {
    //alert("entrar")
    setCargando(true)
    const URL = `${API_URL}/loginLlavero`;
    try {

      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          codigo: id
        }),

        headers: { "Content-Type": "application/json" }
      });

      const resp = await response.json();
      console.log(resp)

      if (resp.msg == "200") {
        const datos = resp.respuesta;

        setTimeout(() => {
          Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
          Cookies.set("galleta", JSON.stringify(['true', datos[0].rol, datos[0].id, datos[0].correo]));
          setCargando(false);
        }, 2000);

      } else if (resp.msg == "300") {

        setTimeout(() => {
          setCargando(false)
          alert(resp.respuesta)
        }, 2000);

      }
      else if (resp.msg == "400") {
        setCargando(false)
        alert("algo salio mal")
      }

    } catch (error) {
      alert("Fallo en la conexión a internet")
      setCargando(false)

    }

  }


  return (
    <>
<Cargando visible={cargando}/>
      <div style={{ width: '100vw', justifyContent: 'center', alignItems: 'flex-start', display: 'flex' }}>


        <div style={{ width: '90%', alignSelf: 'auto', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>

          <img alt="Modern Design" src={llavero} style={{ width: '50%', marginTop: 10 }} />

          <div style={{ width: '50%', marginTop: 10, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Text style={{ color: 'grey', fontWeight: 'bold' }}>ID Llavero: {id}</Text>
            <Button
              type="primary" onClick={()=>{entrar()}} style={() => Cookies.set("colorPrimary", '#fff')} icon={<LoginOutlined />} size='large' className="login-form-button" style={{ width: '90%', marginTop: 10, alignSelf: 'center', backgroundColor: color, borderColor: color }}>
              Entrar
            </Button>
          </div>

        </div>

      </div>



    </>
  )
}

export default Primero;