import React,{useEffect} from "react";
import ReactDOM from "react-dom";

import { Layout, Menu, Avatar, Dropdown, Button, Space, Badge, Anchor, Drawer,Divider  } from "antd";
import {
  UserOutlined,
   PlusOutlined,
HomeOutlined,
FolderOpenOutlined,
SyncOutlined,
CommentOutlined,
DeploymentUnitOutlined,
TeamOutlined, ScheduleOutlined
} from "@ant-design/icons";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';


const { Header, Sider, Content } = Layout;
const {SubMenu} = Menu;
const MenuPrincipal = (props) =>{

  
  useEffect(() => {
   
    console.log(props.rol)

  return () => {};
},[]);
    return (
        <Menu theme='dark' mode="inline" defaultSelectedKeys={[props.ultimaRuta]} className="menu-lateral">
      <Menu.Item key="Inicio"  icon={<HomeOutlined />} style={{color:'white'}} className='menu-item'>
        Inicio 
        <Link to="/Dashboard/Inicio" /> 
        </Menu.Item>
                            <Menu.Item key="Usuarios"  icon={<UserOutlined />} style={{color:'white'}} className='menu-item'>
                            Usuarios   
                            <Link to="/Dashboard/Usuarios" /> 
                            </Menu.Item>

                            <Menu.Item key="Analisis" icon={<SyncOutlined/>} style={{color:'white'}} className='menu-item'>
                            Análisis situacional
                            <Link to="/Dashboard/Analisis" />
                            </Menu.Item>

                            <SubMenu icon={<CommentOutlined/>} style={{color:'white'}} title="Pensamiento Est.">
                            <Menu.Item style={{color:'white'}} key="5">
                              Largo plazo 
                              <Link to="/Dashboard/Pensamiento" />
                            </Menu.Item>
                            <Menu.Item style={{color:'white'}} key="6">
                              Mediano plazo
                              <Link to="/Dashboard/Pensamiento2" />
                              </Menu.Item>
                              <Menu.Item key="Banco" style={{color:'white'}} className='menu-item'>
                            Banco de ideas
                            <Link to="/Dashboard/Banco" />
                            </Menu.Item>   
                          </SubMenu>

                          <SubMenu icon={<DeploymentUnitOutlined/>} style={{color:'white'}} title="Planeación Est.">
                          <Menu.Item key="Planeacion"  style={{color:'white'}} className='menu-item'>
                            Corto plazo
                            <Link to="/Dashboard/Planeacion" />
                            </Menu.Item>
                            <Menu.Item key="Trimestral" style={{color:'white'}} className='menu-item'>
                            Trimestral
                            <Link to="/Dashboard/Trimestral" />
                            </Menu.Item>
                          </SubMenu>

                          <SubMenu icon={<ScheduleOutlined/>} style={{color:'white'}} title="Reuniones">      
                          <Menu.Item key="agregarReunion"  style={{color:'white'}} className='menu-item'>
                            Agregar reunión
                            <Link to="/Dashboard/AgregarReunion" />
                            </Menu.Item>
                            <Menu.Item key="ListaReuniones" style={{color:'white'}} className='menu-item'>
                            Lista de reuniones
                            <Link to="/Dashboard/ListaReuniones" />
                            </Menu.Item>
                          </SubMenu>

  
                            {props.rol=="ConsultorPrincipal" || props.rol=="Administrador"?
                              <Menu.Item key="Consultores" icon={ <TeamOutlined/>} style={{color:'white'}} className='menu-item'>
                              Lista consultores
                              <Link to="/Dashboard/Consultores" />
                              </Menu.Item>
                              :
                              null
                            }

                            

                          <Menu.Item key="AgregarEmpresa" icon={ <PlusOutlined/>} style={{color:'white'}} className='menu-item'>
                              Agregar empresa
                              <Link to="/Dashboard/AgregarEmpresa" />
                              </Menu.Item>
                        
                </Menu>
    )
}

export default MenuPrincipal;