import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, EditFilled, InfoCircleOutlined, RightOutlined, RobotFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, Link } from "react-router-dom";
import { API_URL, } from '../componentes/WebServices/Variables';
const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
  const history = useHistory()

  useEffect(() => {


  }, [])

  return (
    <>
      {/*position:'fixed',top:0,width:'100%'*/}
      <Header className="site-layout-background" style={{ padding: 0,backgroundColor:'white'}}>
        <Button icon={<ArrowLeftOutlined style={{fontSize: 20}}/>} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px'}} onClick={() =>history.goBack()}></Button>
        <Text style={{ fontSize: 20, marginLeft: 15,float:'left',marginTop:-2 }}>Configuración</Text>
      </Header>

      <div style={{ width: '100vw',  justifyContent: 'flex-start', display: 'flex', flexDirection: 'column' }}>
 
        <Link to="/Dashboard/SoporteApp">
          <Button type="text" style={{textAlign: 'left', width: '93%', backgroundColor: 'white', borderColor: 'white', color: 'black',margin:8 }}>
           <div style={{width:'100%',display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: 'center', flexDirection: 'row'}}>
           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <RobotFilled style={{ justifySelf: 'flex-start',fontSize:20  }} />
            <Text style={{ justifySelf: 'flex-start', marginLeft: 8,fontSize:17 }}>Soporte App</Text>
          </div>
           <RightOutlined style={{  }} />
           </div> 
          </Button>
        </Link>

        <Link to="/Dashboard/AcercaDe">
          <Button type="text" style={{textAlign: 'left', width: '93%', backgroundColor: 'white', borderColor: 'white', color: 'black',margin:8  }}>
           <div style={{width:'100%',display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: 'center', flexDirection: 'row'}}>
           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <InfoCircleOutlined style={{ justifySelf: 'flex-start',fontSize:20 }} />
            <Text style={{ justifySelf: 'flex-start', marginLeft: 8, fontSize:17 }}>Acerca de</Text>
          </div>
           <RightOutlined style={{  }} />
           </div> 
          </Button>
        </Link>
  


      </div>


    </>
  )
}

export default Primero;