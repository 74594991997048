import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Table, Tag, Space, Typography, Layout, } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, } from '../componentes/WebServices/Variables';
import ModalInfoReporte from '../componentes/Utilidades/ModalInfoReporte'
const { Header } = Layout;
const { Title, Text } = Typography;

const { Column, ColumnGroup } = Table;

const vacio = {
  emptyText: (
    <div>
      <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
      <p style={{ color: '#D9D9D9' }}>No hay reportes hechos aún</p>
    </div>)
}


const Primero = (props) => {
  const history = useHistory()
  const location = useLocation();

  const [searchSelected, setSearchSelected] = React.useState(false);
  const [reporte, setReporte] = React.useState('');

  const [visibleMenu, setVisibleMenu] = React.useState(false);

  const [canRefresh, setCanRefresh] = React.useState(true);
  const [onRefresh, setOnRefresh] = React.useState(false);

  const [data, setData] = React.useState({
    reportes2: [{ id: 1, folio: "7382674", tramite: "Bache", fecha: "21/06/2021", status: "En proceso" }, { id: 2, folio: "7382674", tramite: "Bache", fecha: "21/06/2021", status: "En proceso" },
    { id: 3, folio: "7382674", tramite: "Bache", fecha: "21/06/2021", status: "En proceso" }],
    reportes: [],
    reportesRespaldos: [],
    fotosReportes: [],
    tramiteSelected: '',
    fechaSelected: '',
    statusSelected: '',
    folioSelected: '',
    colorStatusSelected: '',
    numberLoads: 20,
    load: true,
    hayReportes: false,
    errorInternet: false,
    filtroStatus: 'Todos',
    estadoCompletoSelected: '',
    idUsuario: '',
    correoUsuario: ''
  });

  useEffect(() => {


    if (searchSelected) {
      // inputEl.current.input.focus;
    }

    const { idUsuario, correoUsuario } = location.state;
    setData({
      ...data,
      idUsuario: idUsuario,
      correoUsuario: correoUsuario
    });
    getDatos(idUsuario)

  }, [])

  const getDatos = async (idUsuario) => {

    const requestInfo = {
      method: 'POST',
      body: JSON.stringify({
        idUsuario: idUsuario,
        numberLoads: data.numberLoads,
        filtroStatus: data.filtroStatus
      }),

      headers: { "Content-Type": "application/json" }
    };
    const URL = `${API_URL}/getMisReportes`;

    try {
      const response = await fetchWithTimeout(URL, requestInfo, 5000);
      const resp = await response.json();
      if (resp.msg == "200") {
        const datos = resp.respuesta;
        console.log(datos)
        if (resp.todo == 1) { //si ya es todo

          setCanRefresh(false)
        } else {

          setCanRefresh(true)
        }
        if (datos.length == 0) {
          setData({
            ...data,
            reportes: datos,
            reportesRespaldos: datos,
            load: false,
            hayReportes: false,
            errorInternet: false,

          });
        } else {
          setData({
            ...data,
            reportes: datos,
            reportesRespaldos: datos,
            load: false,
            hayReportes: true,
            errorInternet: false,
          });
        }

      } else if (resp.msg == "300") {

      } else if (resp.msg == "400") {
        //alert("algo salió mal")
      }
    }
    catch (error) {

      if (error == "TypeError: Network request failed") { //problema con la conexion
        setData({
          ...data,
          load: false,
          errorInternet: true
        });
      } else if (error == "timeout") { //problema con el servidor que tarda
        setData({
          ...data,
          load: false,
          errorInternet: true
        });
      }
    }


  }

  const columns = [
    {
      title: 'N° Folio',
      dataIndex: 'folio',
      key: 'folio',
      // width:'20%'
    },
    {
      title: 'Tramite',
      dataIndex: 'tramite',
      key: 'tramite',
      //  width:'20%'
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      render: (_, record) => {
        return (
          <Text style={{ color: '#171b27', fontFamily: 'Sen-Regular' }}>{("0" + (new Date(record.fecha).getDate())).slice(-2) + "/" + ("0" + (new Date(record.fecha).getMonth() + 1)).slice(-2) + "/" + new Date(record.fecha).getFullYear()}</Text>

        )
      }
      // width:'10%'
    },
    {
      title: 'Status',
      dataIndex: 'estado',
      key: 'status',
      width: 125,
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, alignItems: 'center' }}>
            <div style={{ width: 14, height: 14, borderRadius: 7, backgroundColor: `${record.color}`, marginRight: 5 }}></div>
            <Text style={{ color: '#171b27', fontFamily: 'Sen-Regular' }}>{record.estado}</Text>
          </div>
        )
      }
    },
  ];


  const onChange = e => {

    alert(e.target.value)
  }


  const abriModal = async (id, folio, tramite, fecha, status, colorStatus,estadoCompleto) => {
  
    const URL = `${API_URL}/getImagenesReporte`;
    try {

      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          idUsuario: location.state.idUsuario,
          folio: folio
        }),

        headers: { "Content-Type": "application/json" }
      });

      const resp = await response.json();
        console.log(resp,folio)

      if (resp.msg == "200") {
        const datos = resp.respuesta;

        setData({
          ...data,
          fotosReportes: datos,
          tramiteSelected: tramite,
          fechaSelected: fecha,
          statusSelected: status,
          colorStatusSelected: colorStatus,
          folioSelected: folio,
          estadoCompletoSelected:estadoCompleto
        });
        setVisibleMenu(true)
        

      } else if (resp.msg == "300") {

      } else if (resp.msg == "400") {
        alert("algo salió mal")
      }

    } catch (error) {

    }

  }

  const buscar = (val) => {
    console.log(val);
    setReporte(val);

    let filtrados = data.reportesRespaldos.filter((item) => item.folio.match(val) || item.tramite.match(val));
    console.log(filtrados)
    setData({
      ...data,
      reportes: filtrados
    });

  }

  return (
    <>
      {/*position:'fixed',top:0,width:'100%'*/}
      <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
        <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>

        {searchSelected ?

          <Input onChange={(val) => {
            buscar(val.target.value)
        }}
         autoFocus prefix={<SearchOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Buscar" style={{ width: '70%', borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
          :

          <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Mis reportes</Text>
        }


        {searchSelected ?
          <Button icon={<CloseOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'right', marginRight: '12px' }}
            onClick={() => { 
              setSearchSelected(false)
              setReporte('')
              setData({
                ...data,
                reportes: data.reportesRespaldos
              });
            }}
          ></Button>

          :
          <Button icon={<SearchOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'right', marginRight: '12px' }} onClick={() => { setSearchSelected(true) }}></Button>
        }


      </Header>


      <Table locale={vacio} columns={columns} dataSource={data.reportes} pagination={false} onRow={(record, rowIndex) => {
        return {
          onClick: event => { 
            console.log(record)    
            abriModal(record.id,record.folio,record.tramite,("0" + (new Date(record.fecha).getDate())).slice(-2) + "/" + ("0" + (new Date(record.fecha).getMonth() + 1)).slice(-2) + "/" + new Date(record.fecha).getFullYear() + " " + new Date(record.fecha).getUTCHours() + ":" + new Date(record.fecha).getMinutes(),record.estado,record.color,record.estadoCompleto)
       
          },
        };
      }} />

      <ModalInfoReporte visible={visibleMenu}
        entendido={() => {
          setVisibleMenu(false)
        }}
        tramite={data.tramiteSelected}
        folio={data.folioSelected}
        fecha={data.fechaSelected}
        estadoCompleto={data.estadoCompletoSelected}
        colorStatus={data.colorStatusSelected}
        status={data.statusSelected}
        fotos={data.fotosReportes}
      />


    </>
  )
}

export default Primero;

export async function fetchWithTimeout(url, options, timeout = 5000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
  ]);
}