import React, { useEffect, useRef, memo } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout } from 'antd';
import { UserOutlined, LockFilled, EyeFilled, EyeInvisibleFilled, LoginOutlined, MenuOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import history from '../componentes/Contexto/history';
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
import buzon from '../recursos/buzon.png';
import Cookies from 'js-cookie';
import Cargando from '../componentes/Utilidades/Cargando'
import { reactLocalStorage } from 'reactjs-localstorage';
import appIcon from '../recursos/appIcon.png';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, useHistory, withRouter } from 'react-router-dom';
const { Header } = Layout;
const { Title, Text } = Typography;

const AppHome = (props) => {

  const [data, setData] = React.useState({
    firstTime: false,
    tramites: []
  });
  const [loading, setLoading] = React.useState(true);
  const [cargando, setCargando] = React.useState(false)

  useEffect(() => {

    //reactLocalStorage.remove('reportesLocales');

    /*   var cookie = Cookies.get("tramitesLista");
       if(cookie!=null && cookie!=undefined){
         var arr = JSON.parse(cookie);
         console.log(arr)
       }else{
         alert("no existe cookie")
         getTramites()
       }*/

    //alert("hola")
    // ref.current.scrollIntoView()

    // getTramites()
  }, []);

  const getTramites = async () => {

    const URL = `${API_URL}/getTramites`;
    try {
      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          idUsuario: props.idUsuario
        }),
        headers: { "Content-Type": "application/json" }
      });
      const resp = await response.json();

      if (resp.msg === "200") {
        const datos = resp.respuesta;
        console.log(datos)

        /*    var arra = []
           console.log(datos.length)
           for (var i = 0; i < datos.length; i++) {
             arra.push({
               id: datos[i].id,
             })
           }
          var cookie = Cookies.get("tramitesLista");
           if (cookie != null && cookie != undefined) {
            
             var arr = JSON.parse(cookie);
             console.log(arra)
             console.log(arr)
              if(JSON.stringify(arra) == JSON.stringify(arr)){
                 //  alert("igual y no re render")
              }else{
               Cookies.set("tramitesLista", arra);
               setData({
                 ...data,
                 tramites: datos
               });
              }
           }else{
             alert("igual y no re render")
             Cookies.set("tramitesLista", arra);
             setData({
               ...data,
               tramites: datos
             });
           }*/

        setData({
          ...data,
          tramites: datos
        });



      } else if (resp.msg === "300") {

      }

      else if (resp.msg === "400") {

      }

    } catch (error) {

    }
  }

  return (
    <>
      <Cargando visible={cargando} />

      {/*position:'fixed',top:0,width:'100%'*/}

      <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white', height: 180, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button icon={<MenuOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => props.abrirMenu()}></Button>
        <div style={{ height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', width: '100%', display: 'flex', marginLeft: -20 }}>
          <img src={`${API_URL}/getImagen/home/${CLAVE_MUNICIPIO}`} style={{ height: '50%', objectFit: 'contain' }} />
        </div>

      </Header>


      <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white' }}>

        <div style={{ marginTop: 0 }}></div>
        <div style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap', alignItems: 'center', display: 'flex', width: '100%' }}>

          {props.tramites.map(ele => {

            if (ele.sub_menus == 1) {
              return (
                <Link
                  to={{ pathname: "/Dashboard/SubMenus", state: { idUsuario: props.idUsuario, idTramite: ele.id, regresoContador: 1, color: ele.color } }}
                  key={ele.id} style={{ borderColor: '#D8D8D8', textAlign: 'center', alignItems: 'center', width: '33.333333%', height: 140, justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundColor: ele.color }}>
                  <img src={ele.iconoBlanco} style={{ width: '45%' }} />
                  <Text style={{ color: 'white', marginTop: 5 }}>{ele.tramite}</Text>
                </Link>
              )
            } else {

              if(ele.tramite=='Apoyos Sociales')
              {
                return (
                  <Link
                    //  to="/Dashboard/Reporte" 
                    to={{ pathname: "/Dashboard/ApoyosSociales", state: { idUsuario: props.idUsuario } }}
                    key={ele.id} style={{ borderColor: '#D8D8D8', textAlign: 'center', alignItems: 'center', width: '33.333333%', height: 140, justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundColor: ele.color }}>
                    <img src={ele.iconoBlanco} style={{ width: '45%' }} />
                    <Text style={{ color: 'white', marginTop: 5 }}>{ele.tramite}</Text>
                  </Link>
                )
              }else{
                return (
                  <Link
                    //  to="/Dashboard/Reporte" 
                    to={{ pathname: "/Dashboard/Reporte", state: { idUsuario: props.idUsuario, idTramite: ele.id, nombreTramite: ele.tramite, regresoContador: 1, descripcion: ele.descripcion, geolocalizacion: ele.geolocalizacion, tipo: 'menu', idDepartamento: ele.id_departamento, color: ele.color } }}
                    key={ele.id} style={{ borderColor: '#D8D8D8', textAlign: 'center', alignItems: 'center', width: '33.333333%', height: 140, justifyContent: 'center', display: 'flex', flexDirection: 'column', backgroundColor: ele.color }}>
                    <img src={ele.iconoBlanco} style={{ width: '45%' }} />
                    <Text style={{ color: 'white', marginTop: 5 }}>{ele.tramite}</Text>
                  </Link>
                )
              }
              

            }

          })}



          <Link
            to={{ pathname: "/Dashboard/QuejasSugerencias", state: { idUsuario: props.idUsuario } }}
            style={{ backgroundColor: '#A81F72', textAlign: 'center', alignItems: 'center', width: '33.333333%', height: 140, justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <img src={appIcon} style={{ width: '30%' }} />
            <Text style={{ color: 'white', marginTop: 5 }}>Mejoras a la app</Text>
          </Link>

        </div>


        {/*  <div style={{ marginTop: 0 }}></div>

        {props.tramites.map(ele => {
          if (ele.sub_menus == 1) {
            return (
              <Link
                to={{ pathname: "/Dashboard/SubMenus", state: { idUsuario: props.idUsuario, idTramite: ele.id, regresoContador: 1 } }}
                key={ele.id} style={{ borderStyle: 'solid', borderColor: '#D8D8D8', borderWidth: 1.5, borderRadius: 10, textAlign: 'center', alignItems: 'center', width: '90%', height: 140, justifyContent: 'center', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                <img src={ele.icono} style={{ width: '20%' }} />
                <Text>{ele.tramite}</Text>
              </Link>
            )
          } else {
            return (
              <Link
                //  to="/Dashboard/Reporte" 
                to={{ pathname: "/Dashboard/Reporte", state: { idUsuario: props.idUsuario, idTramite: ele.id, nombreTramite: ele.tramite, regresoContador: 1, descripcion: ele.descripcion, geolocalizacion: ele.geolocalizacion, tipo: 'menu', idDepartamento: ele.id_departamento } }}
                key={ele.id} style={{ borderStyle: 'solid', borderColor: '#D8D8D8', borderWidth: 1.5, borderRadius: 10, textAlign: 'center', alignItems: 'center', width: '90%', height: 140, justifyContent: 'center', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                <img src={ele.icono} style={{ width: '20%' }} />
                <Text>{ele.tramite}</Text>
              </Link>
            )
          }

        })}

        <Link
          to={{ pathname: "/Dashboard/QuejasSugerencias", state: { idUsuario: props.idUsuario } }}
          style={{ borderStyle: 'solid', borderColor: '#D8D8D8', borderWidth: 1.5, borderRadius: 10, textAlign: 'center', alignItems: 'center', width: '90%', height: 140, justifyContent: 'center', marginTop: 10, display: 'flex', flexDirection: 'column', marginBottom: 30 }}>
          <img src={buzon} style={{ width: '20%' }} />
          <Text>Quejas y sugerencias</Text>
      </Link>*/}

      </Row>
    </>
  );
}

export default AppHome;