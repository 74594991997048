import React, { useEffect, useState } from 'react';
import AuthApi from '../componentes/Contexto/AuthApi';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';
import { useHistory,useLocation } from "react-router-dom";
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography,Layout } from 'antd';
import { UserOutlined, LockFilled, EyeFilled, EyeInvisibleFilled, LoginOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import logo from '../recursos/ZitCam.png';
import history from '../componentes/Contexto/history';
import { API_URL, } from '../componentes/WebServices/Variables'
import CryptoJS from "crypto-js";
import Cargando  from '../componentes/Utilidades/Cargando'
const { Title, Text } = Typography;
const { Header } = Layout;
const { TabPane } = Tabs;

const Login = (props) => {
  const Auth = React.useContext(AuthApi);

  const location = useLocation();

  const [data, setData] = React.useState({
    backgroundSelected: '#52796F',
    colorSelected: 'white',
    backgroundSelected2: 'rgba(82,121,111,.2)',
    colorSelected2: '#52796F',

  });


  const [cargando, setCargando] = React.useState(false)
  
  const [locationKeys, setLocationKeys] = useState([])
  const history = useHistory()

  useEffect(() => {
    //  Cookies.set("colorPrimary", '#fff');
    //Cookies.remove("colorPrimary")

    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)

          // Handle forward event

        } else {
          setLocationKeys((keys) => [location.key, ...keys])

          // Handle back event

        }
      }
    })
  }, [locationKeys,])




  const onFinish = async (values) => {

    Object.keys(Cookies.get()).forEach(function (cookieName) {
      var neededAttributes = {
        // Here you pass the same attributes that were used when the cookie was created
        // and are required when removing the cookie
      };
      Cookies.remove(cookieName, neededAttributes);
    });


      let contraEncriptada = CryptoJS.AES.encrypt(values.password, 'fookingSmooth').toString();
      login(values.correo,contraEncriptada)
  


  }

  const login = async(usuario,password) =>{


      setCargando(true)
      console.log(usuario,password)

      const URL = `${API_URL}/loginCiudadano`;
      try {

        const response = await fetch(URL, {
          method: 'POST',
          body: JSON.stringify({
            usuario: usuario.replace("\n", "").trim(),
            password: password,
          }),

          headers: { "Content-Type": "application/json" }
        });

        const resp = await response.json();
        console.log(resp)

        if (resp.msg == "200") {
          const datos = resp.respuesta;
          console.log(datos[0].id_rol)
  
          setTimeout(() => {
            Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
            Cookies.set("galleta", JSON.stringify(['true', datos[0].rol, datos[0].id, datos[0].correo]));
            setCargando(false);
          }, 2000);

 

        } else if (resp.msg == "300") {
          setTimeout(() => {
            setCargando(false)
            
            Modal.warning({
              title: 'Alerta!',
              content: (
                <div>
                  <p>{resp.respuesta}</p>
                </div>
              ),
              onOk() {},
            });

          }, 2000);

        }
        else if (resp.msg == "400") {
          setCargando(false)
          Modal.warning({
                title: 'Alerta!',
                content: (
                  <div>
                    <p>Algo salió mal. Intentelo mas tarde.</p>
                  </div>
                ),
                onOk() {},
              });
        }

      } catch (error) {
        setCargando(false)
      }


  }



  return (
    <>
    <Cargando visible={cargando}/>

    <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Inicia sesión</Text>
            </Header>

      <Row className="login" style={{ height: '100vh', width: '100vw', backgroundColor: 'white' }}>
        <div style={{ width: '100vw', paddingBottom: '20px', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginTop: 50 }}>

          <div style={{ width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <img alt="Modern Design" src={logo} style={{ width: '100px' }} />
          </div>

          <Form
            name="normal_login"

            //className="login-form"
            style={{ marginTop: '30px', width: '80%', display: 'flex', flexDirection: 'column' }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >

            <Form.Item
              name="correo"
              rules={[{ required: true, type: 'email', message: 'Ingresa un correo electrónico valido!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Correo o telefono" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Ingresa un correo electrónico valido!' }]}
            >

              <Input.Password  iconRender={visible => (!visible ? <EyeFilled /> : <EyeInvisibleFilled />)} prefix={<LockFilled className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Contraseña" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
            </Form.Item>


          <Form.Item>

            <Button
              type="primary" htmlType="submit" style={() => Cookies.set("colorPrimary", '#fff')} icon={<LoginOutlined />} size='large' className="login-form-button" style={{ width: '80vw', marginTop: 10, backgroundColor: props.colorPrimary, borderColor: props.colorPrimary }}>
              Entrar
            </Button>

          </Form.Item>


          </Form>



        </div>
      </Row>


      {/*  <Row align="middle" justify='center' className="login" style={{ height: '100vh', backgroundColor: 'white' }}>
        <Row align="middle" justify='center' style={{ height: '90vh', width: '100vw', paddingBottom: '20px' }}>


          <Col lg={{ span: 24 }} >

            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
              <img alt="Modern Design" src={logo} style={{ width: '100px' }} />
            </div>

            <Form
              name="normal_login"
              className="login-form"
              style={{ marginTop: '30px' }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <Form.Item
                name="correo"
                rules={[{ required: true, type: 'email', message: 'Ingresa un correo electrónico valido!' }]}
              >
                <Input className="correo" prefix={<UserOutlined className="site-form-item-icon" style={{ color: '#D7D7D7' }} />} placeholder="Correo" style={{ padding: '8px', borderRadius: '5px' }} />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Ingresa tu contraseña!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" style={{ color: '#D7D7D7' }} />}
                  type="password"
                  className="contra"
                  placeholder="Contraseña"
                  style={{ padding: '8px', borderRadius: '5px' }}
                />
              </Form.Item>

              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Recordar</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="" style={{ float: 'right', color: '#2a3b52' }}>
                  Olvide mi contraseña
                </a>
              </Form.Item>

              <Form.Item>

                <Button htmlType="submit"
                  //onClick={()=>entrar()} 
                  type="primary" size='large' htmlType="submit" className="login-form-button" style={{ width: '100%', backgroundColor: '#2a3b52', borderColor: '#2a3b52' }}>
                  Entrar
                </Button>

              </Form.Item>

              <Form.Item>
                Ingresar con:

              </Form.Item>


            </Form>
          </Col>

        </Row>

  </Row>*/}

    </>
  )
}


function error() {
  Modal.error({
    title: 'Invalido!',
    content: 'No existe ningun registro con esas credenciales.',
  });
}

export default Login;
