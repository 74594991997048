import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled, SendOutlined, StarFilled, PhoneOutlined } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, } from '../componentes/WebServices/Variables';
import CryptoJS from "crypto-js";
import Cookies from 'js-cookie';
import { reactLocalStorage } from 'reactjs-localstorage';
import AuthApi from '../componentes/Contexto/AuthApi';
import Cargando from '../componentes/Utilidades/Cargando'
import ModalAgradecimiento from '../componentes/Utilidades/ModalAgradecimiento'
import ModalAgradecimientoLocal from '../componentes/Utilidades/ModalAgradecimientoLocal'

const { Header } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();
    const Auth = React.useContext(AuthApi);

    const numero1 = useRef()
    const numero2 = useRef()
    const numero3 = useRef()
    const numero4 = useRef()
    const numero5 = useRef()

    const [val1, setVal1] = React.useState('');
    const [val2, setVal2] = React.useState('');
    const [val3, setVal3] = React.useState('');
    const [val4, setVal4] = React.useState('');
    const [val5, setVal5] = React.useState('');

    const [cargando, setCargando] = React.useState(false)

    const [data, setData] = React.useState({
        celular: '',
        isvalidcelular: true,
    });
    const [telefono, setTelefono] = React.useState('');
    const [enviado, setEnviado] = React.useState(false);

    const [visibleMenu, setVisibleMenu] = React.useState(false);
    const [visibleMenuLocal, setVisibleMenuLocal] = React.useState(false);


    useEffect(() => {

        const { correo, paterno, materno, nombre, nacimiento, claveMunicipio, password, celular } = location.state;

        return () => {

        };

    }, []);

    const setCelular = (valu) => {
        var val = valu.target.value;
        if (val == '') {
            setData({
                ...data,
                celular: val,
                //   inputColor:'black'
            });

        }
        else {

            if (isNaN(val)) {

            } else {

                if (val.length == 10) {

                    setData({
                        ...data,
                        celular: val,
                        isvalidcelular: true
                    });

                } else {

                    setData({
                        ...data,
                        celular: val,
                        isvalidcelular: false,
                    });

                }

            }

        }


    }

    const enviarCodigo = () => {
        if (data.isvalidcelular == false) {

            alert("Ingrese un número válido")
        } else {
            setVal1('')
            setVal2('')
            setVal3('')
            setVal4('')
            setVal5('')
            setEnviado(true)

            enviarCodigoConfirmado()

        }
    }

    const enviarCodigoConfirmado = async () => {
        setCargando(true)

        const URL = `${API_URL}/validarUsuario`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    numero: data.celular.replace("\n", "").trim(),
                    idUsuario: location.state.idUsuario
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                setTimeout(() => {
                    setCargando(false)
                }, 2000);

            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal")
            }

        } catch (error) {

        }
    }


    const validar = async (val5) => {
        var codigo = val1 + val2 + val3 + val4 + val5;
        setCargando(true)
        const URL = `${API_URL}/validarTokenCuenta`;
        try {
            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    numero: data.celular,
                    token: codigo,
                    idUsuario: location.state.idUsuario
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {


                enviarServer()



            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                alert("algo salio mal4")
                setCargando(false)
            }

        } catch (error) {
            setCargando(false)
        }
    }

    const enviarServer = async () => {
        //alert(location.state.idUsuario)
        setCargando(true)

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify({
                fotosBase: location.state.fotosBase,
                idTramite: location.state.idTramite,
                idUsuario: location.state.idUsuario,
                latitud: location.state.markerLat,
                longitud: location.state.markerLon,
                descripcion: location.state.descripcion,
                status: location.state.status,
                tipo: location.state.tipo,
                ubicacionCompleta: location.state.ubicacionCompleta,
                domicilio: location.state.domicilio,
                colonia: location.state.colonia,
                cp: location.state.cp,
                referencias: location.state.referencias,
                idDepartamento: location.state.idDepartamento
            }),

            headers: { "Content-Type": "application/json" }
        };
        const URL = `${API_URL}/enviarReporte`;

        try {
            const response = await fetch(URL, requestInfo, 5000);
            const resp = await response.json();

            if (resp.msg == "200") {
                setTimeout(() => {
                    setCargando(false)
                    setVisibleMenu(true)
                }, 2000);


            } else if (resp.msg == "300") {

            }
            else if (resp.msg == "400") {

                alert("algo salio mal")
            }

        }
        catch (error) {
            alert(error)
            // enviarLocal()
            if (error == "TypeError: Network request failed") {
                enviarLocal()
            } else if (error == "timeout") {
                setCargando(false)
                setVisibleMenu(true)
            }

        }

    }

    const enviarLocal = async () => {
        console.log('local')

        setCargando(true)

        let reportes = {
            fotosBase: location.state.fotosBase,
            fotos: location.state.fotos,
            idTramite: location.state.idTramite,
            nombreTramite: location.state.nombreTramite,
            idUsuario: location.state.idUsuario,
            latitud: location.state.markerLat,
            longitud: location.state.markerLon,
            descripcion: location.state.descripcion,
            status: location.state.status,
            fecha: new Date(),
            tipo: location.state.tipo,
            ubicacionCompleta: location.state.ubicacionCompleta,
            domicilio: location.state.domicilio,
            colonia: location.state.colonia,
            cp: location.state.cp,
            referencias: location.state.referencias,
            idDepartamento: location.state.idDepartamento
        }


        var reportesLocales = reactLocalStorage.get('reportesLocales') || '[]';
        reportesLocales = JSON.parse(reportesLocales);
        reportesLocales.push(reportes);
        reactLocalStorage.setObject('reportesLocales', reportesLocales);
        setTimeout(() => {
            setCargando(false)
            setVisibleMenuLocal(true)
        }, 1000);
    }

    const reenviar = async () => {

        setCargando(true)
        const URL = `${API_URL}/enviarTokenCuenta`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    numero: data.celular,
                    // token: codigo,
                    idUsuario: location.state.idUsuario
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            if (resp.msg == "200") {

                setTimeout(() => {
                    setCargando(false)
                    // setTimeLeft(60)
                }, 2000);

            } else if (resp.msg == "300") {
                setCargando(false)
                alert("algo salio mal")
            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal2")
            }

        } catch (error) {
            setCargando(false)
        }
    }

    return (
        <>
            <Cargando visible={cargando} />
            {/*position:'fixed',top:0,width:'100%'*/}
            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}></Text>
            </Header>


            {!enviado ?
                <div style={{ width: '100vw', justifyContent: 'centeer', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '90%', marginTop: -20, textAlign: 'center' }}>
                        <Text style={{ color: 'black', fontSize: 25 }}>Validar cuenta</Text>
                    </div>
                    <div style={{ width: '90%', marginTop: 10, textAlign: 'center' }}>
                        <Text style={{ color: 'black' }}>Para poder hacer reportes. Es necesario validar tu cuenta con tu número de celular.</Text>
                    </div>

                    <div style={{ width: '80%', height: 55, display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: 'center', flexDirection: 'row', marginTop: 30 }}>

                        <Input onChange={(val) => {
                            setCelular(val)
                        }} type="number" prefix={<PhoneOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Celular" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
                    </div>



                    <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: 30 }}>
                        <Button
                            type="primary" icon={<SendOutlined />} size='large'
                            onClick={() => enviarCodigo()}
                            className="login-form-button" style={{ width: '100%', marginTop: 10, backgroundColor: 'black', borderColor: 'black' }}>
                            Enviar
                        </Button>
                    </div>

                </div>


                :
                <div style={{ width: '100vw', justifyContent: 'centeer', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '90%', marginTop: -20, textAlign: 'center' }}>
                        <Text style={{ color: 'black', fontSize: 25 }}>Validar cuenta</Text>
                    </div>
                    <div style={{ width: '90%', marginTop: 10, textAlign: 'center' }}>
                        <Text style={{ color: 'black' }}>Para poder hacer reportes. Es necesario validar tu cuenta con tu número de celular.</Text>
                    </div>

                    <div style={{ width: '80%', height: 55, display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: 'center', flexDirection: 'row', marginTop: 30 }}>

                        <Input ref={numero1} value={val1} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white', height: '100%' }}
                            type="number"
                            onChange={(val) => {

                                console.log(val.target.value)
                                if (val.target.value != "") {
                                    setVal1(val.target.value)
                                    numero2.current.focus()
                                } else {
                                    setVal1(val.target.value)
                                }
                            }}
                            onKeyDown={({ nativeEvent }) => {
                                console.log(nativeEvent.key)
                                if (nativeEvent.key === 'Backspace') {

                                    if (val1 != "") {
                                        //setVal1('')
                                        setVal1('')
                                        setVal2('')
                                        setVal3('')
                                        setVal4('')
                                        setVal5('')
                                    } else {
                                        setVal1('')
                                        numero1.current.focus()
                                    }

                                }
                            }}
                        />
                        <Input ref={numero2} value={val2} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white', height: '100%' }}
                            type="number"
                            onChange={(val) => {
                                if (val.target.value != "") {
                                    setVal2(val.target.value)
                                    numero3.current.focus()
                                } else {
                                    setVal2(val.target.value)
                                }

                            }}
                            onKeyDown={({ nativeEvent }) => {
                                console.log(nativeEvent)
                                if (nativeEvent.key === 'Backspace') {

                                    if (val2 != "") {
                                        //setVal1('')
                                        setVal2('')
                                        setVal3('')
                                        setVal4('')
                                        setVal5('')
                                    } else {
                                        setVal1('')
                                        numero1.current.focus()
                                    }

                                }
                            }}
                        />
                        <Input ref={numero3} value={val3} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white', height: '100%' }}
                            type="number"
                            onChange={(val) => {
                                if (val.target.value != '') {
                                    setVal3(val.target.value)
                                    numero4.current.focus()
                                } else {
                                    setVal3(val.target.value)
                                }

                            }}
                            onKeyDown={({ nativeEvent }) => {
                                console.log(nativeEvent.key)
                                if (nativeEvent.key === 'Backspace') {

                                    if (val3 != "") {
                                        //setVal1('')
                                        setVal3('')
                                        setVal4('')
                                        setVal5('')
                                    } else {
                                        setVal2('')
                                        numero2.current.focus()
                                    }

                                }
                            }}
                        />
                        <Input ref={numero4} value={val4} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white', height: '100%' }}
                            type="number"
                            onChange={(val) => {
                                if (val.target.value != '') {
                                    setVal4(val.target.value)
                                    numero5.current.focus()
                                } else {
                                    setVal4(val.target.value)
                                }

                            }}
                            onKeyDown={({ nativeEvent }) => {
                                console.log(nativeEvent.key)
                                if (nativeEvent.key === 'Backspace') {

                                    if (val4 != "") {
                                        //setVal1('')
                                        setVal4('')
                                        setVal5('')
                                    } else {
                                        setVal3('')
                                        numero3.current.focus()
                                    }

                                }
                            }}
                        />
                        <Input ref={numero5} value={val5} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white', height: '100%' }}
                            type="number"
                            onChange={(val) => {

                                if (val.target.value != '') {
                                    setVal5(val.target.value)
                                    validar(val.target.value)
                                } else {
                                    setVal5(val.target.value)
                                }

                                //  setVal5(val)

                            }}
                            onKeyDown={({ nativeEvent }) => { //solo si no es automatico
                                console.log(nativeEvent.key)
                                if (nativeEvent.key === 'Backspace') {
                                    if (val5 != "") {
                                        //setVal1('')
                                        setVal5('')

                                    } else {
                                        setVal4('')
                                        numero4.current.focus()
                                    }

                                }
                            }}
                        />

                    </div>


                    <div style={{ width: '90%', marginTop: 15, textAlign: 'center' }}>
                        <Text style={{ color: 'black' }}>Si no recibiste el codigo, intente reenviarlo.</Text>
                    </div>


                    {/* <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: 30 }}>
                        <Button
                            type="primary" icon={<SendOutlined />} size='large'
                            onClick={() => reenviar()}
                            className="login-form-button" style={{ width: '100%', marginTop: 10, backgroundColor: 'black', borderColor: 'black' }}>
                            Reennviar
                        </Button>
                        </div>*/}

                </div>


            }


            <ModalAgradecimiento visible={visibleMenu}

                calificanos={() => {
                    setVisibleMenu(false)
                    for (var i = 0; i < location.state.regresoContador; i++) {
                        history.goBack()
                    }


                }}
                entendido={() => {
                    setVisibleMenu(false)
                    for (var i = 0; i < location.state.regresoContador; i++) {
                        history.goBack()
                    }
                }}
                titulo="Gracias!"
                subtitulo="Su reporte se recibió correctamente para su seguimiento."
            />

            <ModalAgradecimientoLocal visible={visibleMenuLocal}

                irBorradores={() => {
                    setVisibleMenuLocal(false)
                    for (var i = 0; i < location.state.regresoContador; i++) {
                        history.goBack()
                    }
                }}
                entendido={() => {
                    setVisibleMenuLocal(false)
                    for (var i = 0; i < location.state.regresoContador; i++) {
                        history.goBack()
                    }
                }}
                titulo="Guardado!"
                subtitulo="Su reporte se guardó en borradores por falta de conexión de internet."
            />



        </>
    )
}

export default Primero;

export async function fetchWithTimeout(url, options, timeout = 5000) {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
    ]);
}