import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, EditFilled, InfoCircleOutlined, RightOutlined, RobotFilled, CloseOutlined } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import QrReader from 'react-qr-scanner'
import QrReader2 from 'modern-react-qr-reader'
import QrReader3 from 'react-qr-reader'
import AuthApi from '../componentes/Contexto/AuthApi';
import llavero from '../recursos/llavero.jpg';
import Cargando from '../componentes/Utilidades/Cargando'
import CryptoJS from "crypto-js";
import Cookies from 'js-cookie';
import { API_URL, APPWEB_VERSION } from '../componentes/WebServices/Variables';
const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {

  const Auth = React.useContext(AuthApi);
  const history = useHistory()
  const [cargando, setCargando] = React.useState(false)
  const [permissionGranted, setPermissionGranted] = React.useState(true)
  const [scan, setScan] = React.useState(300)
  useEffect(() => {

    (async function effectDetectCamera() {
     // await detectCamera();
    })();

  }, [])



  const detectCamera = async () => {
    let stream = null;
    const constraints = {
      video: true
    };

    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      setPermissionGranted(true);
    } catch (error) {
      //alert("Error2 "+error)
      setPermissionGranted(false);
      setPermissionGranted(true);
    }

    /*try{
      await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
      await navigator.mediaDevices.enumerateDevices();
  
      setPermissionGranted(true);
    }catch(error){
      alert(error)
      setPermissionGranted(false);
    }*/
    
  };

  const handleError = (error) => {
    //alert("Error: "+error)
    setPermissionGranted(false);
    setPermissionGranted(true);
  }


  const onSuccess = async (val) => {
    if (val != null) {

      if (scan != false) {

        console.log(val)
        var data = val.split("=");
        var codigo = data[2]
        console.log(codigo)

        if (codigo != null && codigo != undefined) {
          setCargando(true)
          setScan(false)

          const URL = `${API_URL}/loginLlavero`;
          try {

            const response = await fetch(URL, {
              method: 'POST',
              body: JSON.stringify({
                codigo: codigo
              }),

              headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
              const datos = resp.respuesta;

              setTimeout(() => {
                Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
                Cookies.set("galleta", JSON.stringify(['true', datos[0].rol, datos[0].id, datos[0].correo]));
                setCargando(false);
              }, 2000);

            } else if (resp.msg == "300") {

              setTimeout(() => {
                setCargando(false)
                setScan(300)
                alert(resp.respuesta)
              }, 2000);

            }
            else if (resp.msg == "400") {
              setCargando(false)
              setScan(300)
              alert("algo salio mal")
            }

          } catch (error) {
            alert("Fallo en la conexión a internet")
            setCargando(false)
            setScan(300)
          }


        } else {
          alert('Codigo de llavero incorecto')
          setScan(300)
        }



      }

    }
  }


  return permissionGranted ?
    (
      <>
        <Cargando visible={cargando} />
        {/*position:'fixed',top:0,width:'100%'*/}
        <Header className="site-layout-background" style={{ padding: 0, width: '100vw', backgroundColor: 'transparent', position: 'absolute', top: 0, zIndex: 10 }}>
          <Button icon={<CloseOutlined style={{ fontSize: 20, color: 'white' }} />} style={{ backgroundColor: 'transparent', borderColor: 'transparent', height: '100%', float: 'left', marginLeft: '20px' }} onClick={() => history.goBack()}></Button>
        </Header>

        <div style={{ width: '100vw', justifyContent: 'center', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>

          {/*   <QrReader3
          delay={scan}
        onScan={(val)=>onSuccess(val)}
        onError={handleError}
          style={{width:'100vw',height:'60vh'}}
          resolution={1920}
   />*/}

        {/*  <QrReader
            delay={100}
            facingMode="rear" 
            style={{ width: '100vw', height: '75vh' }}
            //  onError={this.handleError}
            onScan={(val) => onSuccess(val)}
        />*/}

         <QrReader2
          delay={300}
          facingMode={"environment"}
          onError={handleError }
          onScan={(val)=>onSuccess(val)}
          style={{width:'100vw',height:'60vh'}}
      />

          <div style={{ width: '100vw', height: '30vh', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <img alt="Modern Design" src={llavero} style={{ width: '80%', marginTop: 10 }} />
          </div>

        </div>



      </>
    )
    :
    <div>Permiso de cámara denegado</div>
    ;


}

export default Primero;