import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider, DatePicker } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, EditFilled, ArrowRightOutlined, MailFilled, CalendarOutlined, CameraFilled, SaveFilled, CheckOutlined } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
import { reactLocalStorage } from 'reactjs-localstorage';
import Geocode from "react-geocode";
import Cargando from '../componentes/Utilidades/Cargando'
import ModalReferencias from '../componentes/Utilidades/ModalReferencias'
import ModalReferenciasCompleta from '../componentes/Utilidades/ModalReferenciasCompleta'
import ModalAgradecimiento from '../componentes/Utilidades/ModalAgradecimiento'
import ModalAgradecimientoLocal from '../componentes/Utilidades/ModalAgradecimientoLocal'

import moment from 'moment';
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();
    //let {correo,nombre,paterno,materno,celular,nacimiento,urlFoto} = useParams();


    const [calendar, setCalendar] = React.useState(false);
    const [continuar, setContinuar] = React.useState(false);
    const [continuar2, setContinuar2] = React.useState(false);
    const [cargando, setCargando] = React.useState(false)

    const [visibleMenu, setVisibleMenu] = React.useState(false);
    const [visibleMenuLocal, setVisibleMenuLocal] = React.useState(false);
    const [visibleMenuLocal2, setVisibleMenuLocal2] = React.useState(false);

    const [visibleModal, setVisibleModal] = React.useState(false);
    const [visibleModal2, setVisibleModal2] = React.useState(false);
    const [visibleModal3, setVisibleModal3] = React.useState(false);
    const [visibleModal4, setVisibleModal4] = React.useState(false);

    const [fotoEdit, setFotoEdit] = React.useState(false)

    const [data, setData] = React.useState({
        markerLat: 19.682915,
        markerLon: -101.172042,

        fotos: [],
        fotosBase: [],
        idTramite: '',
        ubicacionCompletaPermitida: false, //si es false no se pide, si es true si se pide
        ubicacionCompletaObtenida: false,
        ubicacionCompleta: '',

        ubicacionCompletaSelected: '',
        domicilioSelected: '',
        coloniaSelected: '',
        cpSelected: '',

        idUsuario: '',
        get: false

    });


    useEffect(() => {
        var input = navigator.onLine;


        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: latitude, longitude: longitude } }) => {
                console.log(latitude, longitude)



                setData({
                    ...data,
                    markerLat: latitude,
                    markerLon: longitude,
                    get: true,

                });
            }
        );

        //alert(location.state.idUsuario+" "+location.state.idTramite +" "+location.state.nombreTramite+" "+ location.state.regresoContador+" "+ location.state.descripcion+" "+ location.state.tipo+" "+ location.state.idDepartamento)

    }, [])

    const MyMapComponent = compose(
        withProps({
            googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCHqqWPkDEPNOP4DPpS0KO_KlaBpojDIAQ&v=3.exp&libraries=geometry,drawing,places",
            loadingElement: <div style={{ width: `100vw`, height: `100vh` }} />,
            containerElement: <div style={{ width: `100vw`, height: `100vh` }} />,
            mapElement: <div style={{ width: `100vw`, height: `100vh` }} />
        }),
        withScriptjs,
        withGoogleMap
    )(props => (
        <GoogleMap defaultZoom={20} defaultCenter={{ lat: data.markerLat, lng: data.markerLon }}>
            <Marker position={{ lat: data.markerLat, lng: data.markerLon }} />
        </GoogleMap>
    ));


    const getAppConfig = async () => {

        const URL = `${API_URL}/getAppConfigMunicipios`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    claveMunicipio: CLAVE_MUNICIPIO
                  }),
                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                console.log(datos[0].ubicacion_completa)
                if(datos[0].ubicacion_completa==1){
                   getUbicacionCompleta()
                }else{
                   // setVisibleModal(true)
                    enviarReporte('','','','','') /// para cuando no se de afuerza pedir la ubicacion completa, pero si será
                }
                

            } else if (resp.msg == "300") {

            } else if (resp.msg == "400") {
                
            }

        } catch (error) {
            getUbicacionCompleta()
           // setVisibleModal(true)
        }
    }

    const getUbicacionCompleta = async() =>{

        Geocode.setApiKey("AIzaSyCHqqWPkDEPNOP4DPpS0KO_KlaBpojDIAQ&v=3");
        Geocode.fromLatLng(String(data.markerLat), String(data.markerLon)).then(
            (json) => {
                var ubicacionCompleta = json.results[0].formatted_address;
                var datos = json.results[0].formatted_address.split(",");
               

                var domicilio = datos[0];
                var colonia = datos[1].substring(1);
                var cp = datos[2].substring(1).split(" ")[0];
                var municipio = datos[2].substring(1).split(" ")[1];
                var estado = datos[3];

                setData({
                    ...data,
                    ubicacionCompletaSelected: ubicacionCompleta,
                    domicilioSelected: domicilio,
                    coloniaSelected: colonia,
                    cpSelected: cp
                });

                //alert("Ubi completa: " + ubicacionCompleta)

                setVisibleModal2(true)
            },
            (error) => {
                console.log("No se pudo ontener ubi completa: "+error)
                setVisibleModal(true)

            }
        );
        

      
    }


    const onReferencias = (val) =>{
        setVisibleModal2(false)
        enviarReporte(data.ubicacionCompletaSelected,data.domicilioSelected,data.coloniaSelected,data.cp,val.referencias)
    }
    

    const onReferenciasCompleta = (val) =>{
        setVisibleModal(false)
        var ubicacionCompleta = val.domicilio+", "+val.colonia+", "+val.cp;
        enviarReporte(ubicacionCompleta,val.domicilio,val.colonia,val.cp,val.referencias)
    }



    
    const enviarReporte = (ubicacionCompleta,domicilio,colonia,cp,referencias) => {
       
            if (navigator.onLine) {
               checkValidacion(ubicacionCompleta,domicilio,colonia,cp,referencias)
            } else {
               enviarLocal(ubicacionCompleta,domicilio,colonia,cp,referencias)
            }
  
    }

    const checkValidacion = async (ubicacionCompleta,domicilio,colonia,cp,referencias) => {
   
        setCargando(true)
       
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify({
                idUsuario: location.state.idUsuario,
            }),

            headers: { "Content-Type": "application/json" }
        };
        const URL = `${API_URL}/checkUsuarioValidacion`;

        try {
            const response = await fetchWithTimeout(URL, requestInfo, 5000);
            const resp = await response.json();

            if (resp.msg == "200") {
               const datos = resp.respuesta;

               if(datos[0].validado==0){
                   //  alert("no validado")
                   setTimeout(() => {
                    setCargando(false)

                   history.push({
                    pathname: '/Dashboard/ValidarCuenta',
                    state: {fotosBase:location.state.fotosBase,idTramite:location.state.idTramite,markerLat:data.markerLat,markerLon:data.markerLon,status:"En proceso",tipo:location.state.tipo,fotos:location.state.fotos,regresoContador:location.state.regresoContador + 1,descripcion:location.state.descripcion,ubicacionCompleta:ubicacionCompleta,domicilio:domicilio,colonia:colonia,cp:cp,referencias:referencias,idDepartamento: location.state.idDepartamento,idUsuario: location.state.idUsuario},
                  });
               
                }, 2000);
                     
               }else{
                  enviarServer(ubicacionCompleta,domicilio,colonia,cp,referencias)
               }
     

            } else if (resp.msg == "300") {

            }
            else if (resp.msg == "400") {
                alert("algo salio mal")
            }

        }
        catch (error) {
            // enviarLocal()
            if (error == "TypeError: Network request failed") {
                enviarLocal(ubicacionCompleta,domicilio,colonia,cp,referencias)
            }else if(error == "timeout"){
                setCargando(false)
                setVisibleMenu(true)
            }

        }

    }


    const enviarServer = async (ubicacionCompleta,domicilio,colonia,cp,referencias) => {
setCargando(true)

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify({
                fotosBase: location.state.fotosBase,
                idTramite: location.state.idTramite,
                idUsuario: location.state.idUsuario,
                latitud: data.markerLat,
                longitud: data.markerLon,
                descripcion: location.state.descripcion,
                status: "En proceso",
                tipo:location.state.tipo,
                ubicacionCompleta:ubicacionCompleta,
                domicilio:domicilio,
                colonia:colonia,
                cp:cp,
                referencias:referencias,
                idDepartamento: location.state.idDepartamento,
                claveMunicipio: CLAVE_MUNICIPIO
            }),

            headers: { "Content-Type": "application/json" }
        };
        const URL = `${API_URL}/enviarReporteMunicipio`;

        try {
            const response = await fetchWithTimeout(URL, requestInfo, 5000);
            const resp = await response.json();

            if (resp.msg == "200") {
                setTimeout(() => {
                    setCargando(false)
                    setVisibleMenu(true)

                }, 2000);


            } else if (resp.msg == "300") {

            }
            else if (resp.msg == "400") {

                alert("algo salio mal")
            }

        }
        catch (error) {
            // enviarLocal()
            if (error == "TypeError: Network request failed") {
                enviarLocal(ubicacionCompleta,domicilio,colonia,cp,referencias)
            }else if(error == "timeout"){
                setCargando(false)
                setVisibleMenu(true)
            }

        }

    }

    const enviarLocal = async (ubicacionCompleta,domicilio,colonia,cp,referencias) => {
        setCargando(true)

        let reportes = {
            fotosBase: location.state.fotosBase,
            fotos: location.state.fotos,
            idTramite: location.state.idTramite,
            nombreTramite: location.state.nombreTramite,
            idUsuario: location.state.idUsuario,
            latitud: data.markerLat,
            longitud: data.markerLon,
            descripcion: location.state.descripcion,
            status: "En proceso",
            fecha: new Date(),
            tipo:location.state.tipo,
            ubicacionCompleta:ubicacionCompleta,
            domicilio:domicilio,
            colonia:colonia,
            cp:cp,
            referencias:referencias,
            idDepartamento: location.state.idDepartamento
        }

    

        var reportesLocales = reactLocalStorage.get('reportesLocales') || '[]';
        reportesLocales = JSON.parse(reportesLocales);
        reportesLocales.push(reportes);
        reactLocalStorage.setObject('reportesLocales', reportesLocales);
        setTimeout(() => {
            setCargando(false)
            setVisibleMenuLocal(true)
        }, 1000);
    }

    const guardarBorrador = async () => {

        Geocode.setApiKey("AIzaSyCHqqWPkDEPNOP4DPpS0KO_KlaBpojDIAQ&v=3");
        Geocode.fromLatLng(String(data.markerLat), String(data.markerLon)).then(
            (json) => {
                var ubicacionCompleta = json.results[0].formatted_address;
                var datos = json.results[0].formatted_address.split(",");

                var domicilio = datos[0];
                var colonia = datos[1].substring(1);
                var cp = datos[2].substring(1).split(" ")[0];
                var municipio = datos[2].substring(1).split(" ")[1];
                var estado = datos[3];

                setData({
                    ...data,
                    ubicacionCompletaSelected: ubicacionCompleta,
                    domicilioSelected: domicilio,
                    coloniaSelected: colonia,
                    cpSelected: cp
                });

                //alert("Ubi completa: " + ubicacionCompleta)

                setVisibleModal4(true)
            },
            (error) => {
                console.log("No se pudo ontener ubi completa: "+error)
                setVisibleModal3(true)

            }
        );
        // reactLocalStorage.setObject('var', {'test': 'test'});
    }





    const onReferencias2 = (val) =>{
        setVisibleModal4(false)
        enviarLocal2(data.ubicacionCompletaSelected,data.domicilioSelected,data.coloniaSelected,data.codigoPostalSelected,val.referencias)
    }
    

    const onReferenciasCompleta2 = (val) =>{
        setVisibleModal3(false)

        var ubicacionCompleta = val.domicilio+", "+val.colonia+", "+val.cp;
        enviarLocal2(ubicacionCompleta,val.domicilio,val.colonia,val.cp,val.referencias)

    }

    const enviarLocal2 = async (ubicacionCompleta,domicilio,colonia,cp,referencias) => {

        setCargando(true)

        let reportes = {
            fotosBase: location.state.fotosBase,
            fotos: location.state.fotos,
            idTramite: location.state.idTramite,
            nombreTramite: location.state.nombreTramite,
            idUsuario: location.state.idUsuario,
            latitud: data.markerLat,
            longitud: data.markerLon,
            descripcion: location.state.descripcion,
            status: "En proceso",
            fecha: new Date(),
            tipo:location.state.tipo,
            ubicacionCompleta:ubicacionCompleta,
            domicilio:domicilio,
            colonia:colonia,
            cp:cp,
            referencias:referencias,
            idDepartamento: location.state.idDepartamento
        }

       // var reactLocalStorage.getObject('var');


       var reportesLocales = reactLocalStorage.get('reportesLocales') || '[]';
       reportesLocales = JSON.parse(reportesLocales);
       reportesLocales.push(reportes);
       reactLocalStorage.setObject('reportesLocales', reportesLocales);
       setTimeout(() => {
           setCargando(false)
           setVisibleMenuLocal2(true)
       }, 1000);


    }

    return (
        <>
            <Cargando visible={cargando} />
            {data.get ?
                <MyMapComponent />
                :
                null
            }

            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'row', position: 'absolute', bottom: 0, zIndex: 20 }}>
                <Button
                    type="primary" onClick={() => { guardarBorrador() }} icon={<SaveFilled />} size='large' className="login-form-button" style={{ color: 'white', width: '100%', height: 70, backgroundColor: 'grey', borderColor: 'grey' }}>
                    Borradores
                </Button>

                <Button
                    type="primary"  onClick={() => { getAppConfig()}} icon={<CheckOutlined />} size='large' className="login-form-button" style={{ width: '100%', height: 70, backgroundColor: 'black', borderColor: 'black' }}>
                    Confirmar
                </Button>
            </div>

            <ModalReferencias visible={visibleModal2}
                onCreate={(e) => onReferencias(e)}
                onCancel={() => {
                    setVisibleModal2(false)
                }}
                titulo="Agrega referencias del lugar"
            />

            <ModalReferenciasCompleta visible={visibleModal}
                onCreate={(e) => onReferenciasCompleta(e)}
                onCancel={() => {
                    setVisibleModal(false)
                }}
                titulo="Agrega tu ubicacion completa y referencias del lugar"
            />


            <ModalReferencias visible={visibleModal4}
                onCreate={(e) => onReferencias2(e)}
                onCancel={() => {
                    setVisibleModal4(false)
                }}
                titulo="Agrega referencias del lugar"
            />

            <ModalReferenciasCompleta visible={visibleModal3}
                onCreate={(e) => onReferenciasCompleta2(e)}
                onCancel={() => {
                    setVisibleModal3(false)
                }}
                titulo="Agrega tu ubicacion completa y referencias del lugar"
            />


<ModalAgradecimiento visible={visibleMenu}
                
                calificanos={() => {
                    setVisibleMenu(false)
                    for(var i = 0;i<location.state.regresoContador;i++){
                        history.goBack()
                    }
                  
                }}
                entendido={() => {
                    setVisibleMenu(false)
                    for(var i = 0;i<location.state.regresoContador;i++){
                        history.goBack()
                    }
                }}
                titulo="Gracias!"
                subtitulo="Su reporte se recibió correctamente para su seguimiento."
            />

<ModalAgradecimientoLocal visible={visibleMenuLocal}
               
                irBorradores={() => {
                    setVisibleMenuLocal(false)
                   for(var i = 0;i<location.state.regresoContador;i++){
                        history.goBack()
                    }
                }}
                entendido={() => {
                    setVisibleMenuLocal(false)
                    for(var i = 0;i<location.state.regresoContador;i++){
                        history.goBack()
                    }
                }}
                titulo="Guardado!"
                subtitulo="Su reporte se guardó en borradores por falta de conexión de internet."
            />

<ModalAgradecimientoLocal visible={visibleMenuLocal2}
               
                irBorradores={() => {
                    setVisibleMenuLocal2(false)
                    for(var i = 0;i<location.state.regresoContador;i++){
                        history.goBack()
                    }
                }}
                entendido={() => {
                    setVisibleMenuLocal2(false)
                    for(var i = 0;i<location.state.regresoContador;i++){
                        history.goBack()
                    }
                }}
                titulo="Guardado!"
                subtitulo="Su reporte se guardó en borradores"
            />




            
        </>
    )
}

export default Primero;

export async function fetchWithTimeout(url, options, timeout = 5000) {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
    ]);
}