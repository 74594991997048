import React, { useEffect, useState } from 'react';
import AuthApi from '../componentes/Contexto/AuthApi';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie';
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Select, DatePicker } from 'antd';
import { UserOutlined, LockFilled, EyeFilled, EyeInvisibleFilled, LoginOutlined, ArrowLeftOutlined, MailFilled, ArrowRightOutlined, CalendarOutlined,PhoneFilled } from '@ant-design/icons';
import logo from '../recursos/ZitCam.png';
import history from '../componentes/Contexto/history';
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
import CryptoJS from "crypto-js";
import Cargando from '../componentes/Utilidades/Cargando'
import moment from 'moment';
const { Title, Text } = Typography;
const { Header } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

const Login = (props) => {
  const Auth = React.useContext(AuthApi);
  const history = useHistory()

  const [data, setData] = React.useState({
    municipios: [],
    correo: '',
    paterno: '',
    materno: '',
    nombre: '',
    nacimiento: '',
    claveMunicipio: CLAVE_MUNICIPIO,
    password: '',
    celular: ''
  });

  const [cargando, setCargando] = React.useState(false)
  const [continuar, setContinuar] = React.useState(false)

  useEffect(() => {

    getCatalagoMunicipios()
  }, [])

  const getCatalagoMunicipios = async () => {

    const URL = `${API_URL}/getCatalagoMunicipios`;
    try {

      const response = await fetch(URL, {
        method: 'POST',
        headers: { "Content-Type": "application/json" }
      });

      const resp = await response.json();

      if (resp.msg == "200") {
        const datos = resp.respuesta;
        console.log(datos)

        setData({
          ...data,
          municipios: datos
        });


      } else if (resp.msg == "300") {

      } else if (resp.msg == "400") {

      }

    } catch (error) {

    }
  }

  const onFinish = async (values) => {




  }


  const enviarToken = async(password,celular) =>{

   /* history.push({
    pathname: '/ValidarRegistro',
    state: {correo:data.correo,paterno:data.paterno,materno:data.materno,nombre:data.nombre,nacimiento:data.nacimiento,claveMunicipio:data.claveMunicipio,password:password,celular:celular},
  });*/

    setCargando(true)
    const URL = `${API_URL}/enviarTokenRegistro`;
    try {

        const response = await fetch(URL, {
            method: 'POST',
            body: JSON.stringify({
                numero: celular
            }),

            headers: { "Content-Type": "application/json" }
        });

        const resp = await response.json();
        if (resp.msg == "200") {

            setTimeout(() => {
                setCargando(false)
                setContinuar(false) 

                history.push({
                  pathname: '/ValidarRegistro',
                  state: {correo:data.correo,paterno:data.paterno,materno:data.materno,nombre:data.nombre,nacimiento:data.nacimiento,claveMunicipio:data.claveMunicipio,password:password,celular:celular},
                });
               
            }, 2000);

        } else if (resp.msg == "300") {
            alert("algo salio mal")
        }

    } catch (error) {

    }

  }

  return (
    <>
      <Cargando visible={cargando} />

      <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
        <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
        <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Crear cuenta</Text>
      </Header>

      {!continuar ?
        <Row className="login" style={{ height: '100vh', width: '100vw', backgroundColor: 'white' }}>
          <div style={{ width: '100vw', paddingBottom: '20px', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginTop: -10 }}>

            <Form
              name="normal_login"

              //className="login-form"
              style={{ marginTop: '30px', width: '80%', display: 'flex', flexDirection: 'column' }}
              initialValues={{ remember: true }}
              onFinish={(values) => { 
         //       console.log(values.municipio)
                setData({
                  ...data,
                  correo:values.correo,
                  paterno:values.paterno,
                  materno:values.materno,
                  nombre:values.nombre,
                  nacimiento:values.nacimiento.format('DD/MM/YYYY'),
               //   claveMunicipio:values.municipio
                });
                setContinuar(true) 
              }}
            >

              <Form.Item
                name="correo"
                rules={[{ required: true, type: 'email', message: 'Ingresa un correo electrónico válido!' }]}
              >
                <Input className='inputRegistro' prefix={<MailFilled className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Correo" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
              </Form.Item>

              <Form.Item
                name="paterno"
                rules={[{ required: true, message: 'Ingresa un apellido válido!' }]}
              >
                <Input className='inputRegistro' prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Apellido paterno" style={{ backgroundColor:'white',borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
              </Form.Item>

              <Form.Item
                name="materno"
                rules={[{ required: true, message: 'Ingresa un apellido válido!' }]}
              >
                <Input className='inputRegistro' prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Apellido materno" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
              </Form.Item>

              <Form.Item
                name="nombre"
                rules={[{ required: true, message: 'Ingresa un nombre válido!' }]}
              >
                <Input className='inputRegistro' prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Nombre/s" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
              </Form.Item>

              <Form.Item
                name="nacimiento"
                rules={[{ required: true, message: 'Selecciona tu fecha de nacimiento!' }]}
              >
                {/* <Input prefix={<CalendarOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Nacimiento" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />*/}
                <DatePicker
                  //defaultValue={moment('01/01/1996', 'DD/MM/YYYY')} 
                  format={'DD/MM/YYYY'} inputReadOnly={true} onChange={(val) => { }} placeholder="Fecha de nacimiento" showToday={false} style={{ width: '100%', height: 40 }} />
              </Form.Item>


          {/*    <Form.Item
                name="municipio"
                //label="Elige municipio"
                rules={[{ required: true, message: 'Selecciona el municipio!' }]}
              >

                <Select defaultValue="" style={{ height: 40 }}>
                  <Option value="" style={{ color: 'grey' }}>Elige municipio</Option>

                  {data.municipios.map((item, index) => {
                    return (
                      <Option value={item.clave_municipio}>{item.municipio}</Option>
                    )
                  })
                  }
                </Select>

              </Form.Item>*/}



              <Form.Item>

                <Button
                  type="primary" htmlType="submit" style={() => Cookies.set("colorPrimary", '#fff')} icon={<ArrowRightOutlined />} size='large' className="login-form-button" style={{ width: '80vw', marginTop: 10, backgroundColor: props.colorPrimary, borderColor: props.colorPrimary }}>
                  Continuar
                </Button>

              </Form.Item>


            </Form>



          </div>
        </Row>
        :


        <Row className="login" style={{ height: '100vh', width: '100vw', backgroundColor: 'white' }}>
          <div style={{ width: '100vw', paddingBottom: '20px', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column', marginTop: -10 }}>

            <Form
              name="normal_login"

              //className="login-form"
              style={{ marginTop: '30px', width: '80%', display: 'flex', flexDirection: 'column' }}
              initialValues={{ remember: true }}
              onFinish={(values) => { 
                console.log(values.password,values.confirmarPassword)
                if(values.password==values.confirmarPassword){
                  enviarToken(values.password,values.celular)

                }else{
                  Modal.warning({
                    title: 'Alerta!',
                    content: (
                      <div>
                        <p>Las contraseña son diferentes</p>
                      </div>
                    ),
                    onOk() {},
                  });
                }
                

             }}
            >

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Ingresa una contraseña!' }]}
              >

                <Input.Password iconRender={visible => (!visible ? <EyeFilled /> : <EyeInvisibleFilled />)} prefix={<LockFilled className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Contraseña" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
              </Form.Item>

              <Form.Item
                name="confirmarPassword"
                rules={[{ required: true, message: 'Ingresa la confirmación de su contraseña!' }]}
              >

                <Input.Password iconRender={visible => (!visible ? <EyeFilled /> : <EyeInvisibleFilled />)} prefix={<LockFilled className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Contraseña" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
              </Form.Item>


              <Form.Item
                name="celular"
                rules={[{ required: true, message: 'Ingresa tu celular!' },
                {
                  pattern: /^(?:\d*)$/,
                  message: "Solo numeros",
                },
                {
                  pattern: /^[\d]{10,10}$/,
                  message: "Celular incorrecto",
                }]}
              >
              
                <Input type="number" prefix={<PhoneFilled className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Número de celular" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
              </Form.Item>

              <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'row' }}>

                <Form.Item style={{ width: '50%', marginRight: 15 }}>
                  <Button
                    type="primary" onClick={() => setContinuar(false)} icon={<ArrowLeftOutlined />} size='large' className="login-form-button" style={{ color: 'grey', width: '100%', backgroundColor: 'white', borderColor: 'grey' }}>
                    Atras
                  </Button>
                </Form.Item>

                <Form.Item style={{ width: '50%' }}>
                  <Button
                    type="primary" htmlType="submit" style={() => Cookies.set("colorPrimary", '#fff')} icon={<ArrowRightOutlined />} size='large' className="login-form-button" style={{ width: '100%', backgroundColor:props.colorPrimary, borderColor: props.colorPrimary }}>
                    Continuar
                  </Button>
                </Form.Item>

              </div>


            </Form>



          </div>
        </Row>

      }






    </>
  )
}


function error() {
  Modal.error({
    title: 'Inválido!',
    content: 'No existe ningun registro con esas credenciales.',
  });
}

export default Login;
