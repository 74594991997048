import React, { useEffect, useState } from 'react';
import AuthApi from '../componentes/Contexto/AuthApi';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography } from 'antd';
import { MailFilled, QrcodeOutlined, GoogleOutlined, FacebookOutlined, FacebookFilled, UserOutlined, LockFilled, EyeFilled, EyeInvisibleFilled, LoginOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import logo from '../recursos/laheroicablanco.png';
import escudosBlancos from '../recursos/escudos3.png';
import history from '../componentes/Contexto/history';
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
import CryptoJS from "crypto-js";
import { GoogleLogin } from 'react-google-login';
import Cargando from '../componentes/Utilidades/Cargando'
import ModalSelectMunicipio from '../componentes/Utilidades/ModalSelectMunicipio'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import * as Vibrant from 'node-vibrant';

const { Title, Text } = Typography;

const { TabPane } = Tabs;


const Login = (props) => {
    const Auth = React.useContext(AuthApi);

    const [visibleModal, setVisibleModal] = React.useState(false);
    const [visibleModal2, setVisibleModal2] = React.useState(false);
    const [cargando, setCargando] = React.useState(false)
    const [clientId, setClientId] = React.useState("")
    const [facebookId, setFacebookId] = React.useState("")

    const [data, setData] = React.useState({
        backgroundSelected: '#52796F',
        colorSelected: 'white',
        backgroundSelected2: 'rgba(82,121,111,.2)',
        colorSelected2: '#52796F',

        userGoogleInfo: {},
        loaded: false,
        loginFacebook: 0,
        loginGoogle: 0,
        loginLlavero: 0,
        municipios: [],

        token: '',
        correo: '',
        foto: '',
        apellido: '',
        nombre: ''
    });

    const firebaseConfig = {
        apiKey: "AIzaSyBHhrO5SxZwVRsOWynKeGplS1w0DN_yj30",
        authDomain: "c5ciudadano-1c197.firebaseapp.com",
        projectId: "c5ciudadano-1c197",
        storageBucket: "c5ciudadano-1c197.appspot.com",
        messagingSenderId: "69743328048",
        appId: "1:69743328048:web:b3dd5c8da5c21bfcb566f5",
        measurementId: "G-H79VLQD1C1"
    };
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const [color, setColor] = React.useState('#bc1c84');
    const [isEmpresa, setIsEmpresa] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const [locationKeys, setLocationKeys] = useState([])
    const history = useHistory()

    /* useEffect(() => {
         return history.listen(location => {
             if (history.action === 'PUSH') {
                 setLocationKeys([location.key])
             }
 
             if (history.action === 'POP') {
                 if (locationKeys[1] === location.key) {
                     setLocationKeys(([_, ...keys]) => keys)
 
                     // Handle forward event
 
                 } else {
                     setLocationKeys((keys) => [location.key, ...keys])
 
                     // Handle back event
 
                 }
             }
         })
     }, [locationKeys,])*/

    useEffect(() => {
        /*  if (Cookies.get('colorPrimary') != null) {
  
          } else {
              Vibrant.from(`${API_URL}/getImagenMunicipio/${CLAVE_MUNICIPIO}/?${Date.now()}`).getPalette()
                  .then(palette => {
  
                      const paleta = palette.Vibrant._rgb;
                      console.log(palette)
                      const el = document.querySelector("meta[name='theme-color']");
                      const hexa = "#" + fullColorHex(paleta[0], paleta[1], paleta[2]);
                      setColor(hexa)
                      el.setAttribute('content', hexa)
                  })
                  .catch(error => {
                      // handle errors
                  });
          }*/

          if(CLAVE_MUNICIPIO=='112'){
            setClientId("135124777516-07kfbu1utvi05f4tlda5mlckdj7kbenv.apps.googleusercontent.com")
            setFacebookId("986434488815721")
          }else if(CLAVE_MUNICIPIO=='014'){
            setClientId("122418219540-4vmeucjdhc8t8ksnl4prr1h2807aocqs.apps.googleusercontent.com")
            setFacebookId("1198308170991192")
          }

        Vibrant.from(`${API_URL}/getImagenMunicipio/${CLAVE_MUNICIPIO}/?${Date.now()}`).getPalette()
            .then(palette => {

                var paleta;
                if (CLAVE_MUNICIPIO == '112') {
                    paleta = palette.Vibrant.hex;
                } else {
                    paleta = palette.DarkMuted.hex;
                }

                //console.log(palette)
                const el = document.querySelector("meta[name='theme-color']");

                //            const hexa = "#" + fullColorHex(paleta[0], paleta[1], paleta[2]);

                setColor(paleta)
                el.setAttribute('content', paleta)
            })
            .catch(error => {
                // handle errors
            });

        getAppConfig()
        return () => {

        };

    }, []);

    var fullColorHex = function (r, g, b) {
        var red = rgbToHex(r);
        var green = rgbToHex(g);
        var blue = rgbToHex(b);
        return red + green + blue;
    }

    var rgbToHex = function (rgb) {
        var hex = Number(rgb).toString(16);
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex;
    }

    const getAppConfig = async () => {

        const URL = `${API_URL}/getAppConfigMunicipios`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    claveMunicipio: CLAVE_MUNICIPIO
                  }),
                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                getCatalagoMunicipios(datos)

            } else if (resp.msg == "300") {

            } else if (resp.msg == "400") {

            }

        } catch (error) {

        }
    }


    const getCatalagoMunicipios = async (datosAppConfig) => {

        const URL = `${API_URL}/getCatalagoMunicipios`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                console.log(datos)

                setData({
                    ...data,
                    loginFacebook: datosAppConfig[0].sesion_facebook_appweb,
                    loginGoogle: datosAppConfig[0].sesion_google_appweb,
                    loginLlavero: datosAppConfig[0].sesion_llavero_appweb,
                    municipios: datos
                });


            } else if (resp.msg == "300") {

            } else if (resp.msg == "400") {
                setData({
                    ...data,
                    loginFacebook: datosAppConfig[0].sesion_facebook_appweb,
                    loginGoogle: datosAppConfig[0].sesion_google_appweb,
                    loginLlavero: datosAppConfig[0].sesion_llavero_appweb,
                });
            }

        } catch (error) {
            setData({
                ...data,
                loginFacebook: datosAppConfig[0].sesion_facebook_appweb,
                loginGoogle: datosAppConfig[0].sesion_google_appweb,
                loginLlavero: datosAppConfig[0].sesion_llavero_appweb,
            });
        }
    }


    const onFinish = async (values) => {

        Object.keys(Cookies.get()).forEach(function (cookieName) {
            var neededAttributes = {
            };
            Cookies.remove(cookieName, neededAttributes);
        });


        let contraEncriptada = CryptoJS.AES.encrypt(values.password, 'fookingSmooth').toString();
        login(values.correo, contraEncriptada)



    }

    const login = async (usuario, password) => {


        
        console.log(usuario, password)

        if (usuario != undefined && password != '') {
            setCargando(true)
            const URL = `${API_URL}/loginCiudadano`;
            try {

                const response = await fetch(URL, {
                    method: 'POST',
                    body: JSON.stringify({
                        usuario: usuario.replace("\n", "").trim(),
                        password: password,
                    }),

                    headers: { "Content-Type": "application/json" }
                });

                const resp = await response.json();
                console.log(resp)

                if (resp.msg == "200") {
                    const datos = resp.respuesta;
                    console.log(datos[0].id_rol)

                    setTimeout(() => {
                        Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
                        Cookies.set("galleta", JSON.stringify(['true', datos[0].rol, datos[0].id, datos[0].correo]));
                        setCargando(false);
                    }, 1500);



                } else if (resp.msg == "300") {
                    setTimeout(() => {
                        setCargando(false)

                        Modal.warning({
                            title: 'Alerta!',
                            content: (
                                <div>
                                    <p>{resp.respuesta}</p>
                                </div>
                            ),
                            onOk() { },
                        });

                    }, 2000);

                }
                else if (resp.msg == "400") {
                    setCargando(false)
                    Modal.warning({
                        title: 'Alerta!',
                        content: (
                            <div>
                                <p>Algo salió mal. Intentelo mas tarde.</p>
                            </div>
                        ),
                        onOk() { },
                    });
                }

            } catch (error) {
                setCargando(false)
            }


        } else {
            alert("LLene todos los campos")
        }

    }

    const responseGoogle = (response) => {
        console.log(response);
        var token = response.tokenId;
        var correo = response.profileObj.email;
        var foto = response.profileObj.imageUrl;
        var apellido = response.profileObj.familyName;
        var nombre = response.profileObj.givenName;

        setData({
            ...data,
            token: token,
            correo: correo,
            foto: foto,
            apellido: apellido,
            nombre: nombre
        });


        checkSesionExist(correo, nombre, apellido, foto, token)
    }


    const checkSesionExist = async (correo, nombre, apellido, foto, token) => {
        const URL = `${API_URL}/checkSesionExistGoogle`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    correo: correo,
                    claveMunicipio:CLAVE_MUNICIPIO
                }),
                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                console.log(datos)

                if (datos == 1) { //si ya estaba regitsrado, no pedir municipio
                    googleSesion(correo)
                } else {
                   // setVisibleModal(true)//para la generica

                    googleRegistroSesion(correo, nombre, apellido, foto, token, CLAVE_MUNICIPIO)
                }

            } else if (resp.msg == "300") {

            } else if (resp.msg == "400") {

            }

        } catch (error) {

        }
    }

    const googleSesion = async (correo) => {
        setCargando(true)

        const URL = `${API_URL}/sesionCiudadanoGoogle`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    correo: correo,
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                const datos = resp.respuesta;

                if (datos[0] != undefined) {


                    setTimeout(() => {
                        Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
                        Cookies.set("galleta", JSON.stringify(['true', datos[0].id_rol, datos[0].id, datos[0].correo]));

                    }, 1000);
                } else {
                    setCargando(false)
                    alert("Algo salió mal\nIntentelo mas tarde.")
                }

            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal")
            }

        } catch (error) {
            console.log(error)
        }
    }

    const setMunicipio = async (val) => {
        setVisibleModal(false)
        googleRegistroSesion(data.correo, data.nombre, data.apellido, data.foto, data.token, val.claveMunicipio)

    }



    const googleRegistroSesion = async (correo, nombre, apellido, foto, token, claveMunicipio) => {

       // console.log(correo, nombre, apellido, foto, token, claveMunicipio)
        setCargando(true)

        const URL = `${API_URL}/registrarCiudadanoGoogle`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    correo: correo,
                    paterno: apellido,
                    materno: '',
                    nombre: nombre,
                    celular: '',
                    nacimiento: '',
                    password: token,
                    tipoSesion: 'Google',
                    urlFoto: foto,
                    claveMunicipio: claveMunicipio
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {

                const datos = resp.respuesta;
                setTimeout(() => {
                    Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
                    Cookies.set("galleta", JSON.stringify(['true', datos[0].rol, datos[0].id, datos[0].correo]));
                    setCargando(false)
                }, 1500);


            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal")
            }

        } catch (error) {

        }


    }


    const responseFacebook = (response) => {
        console.log(response);

        console.log(response);
        var token = response.accessToken;
        var correo = response.email;
        var foto = response.picture.data.url;
        var nombre = response.name;

      
        setData({
            ...data,
            token: token,
            correo: correo,
            foto: foto,
            nombre: nombre
        });


        checkSesionExistFacebook(correo,nombre,foto,token)

    }

    const checkSesionExistFacebook = async (correo,nombre,foto,token) => {
        const URL = `${API_URL}/checkSesionExistFacebook2`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    correo: correo,
                    claveMunicipio:CLAVE_MUNICIPIO
                }),
                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                console.log(datos)

                if (datos == 1) { //si ya estaba regitsrado, no pedir municipio
                    googleFacebook(correo)
                } else {
                    //setVisibleModal2(true)//

                    facebookRegistroSesion(correo,nombre,foto,token, CLAVE_MUNICIPIO)
                }

            } else if (resp.msg == "300") {

            } else if (resp.msg == "400") {

            }

        } catch (error) {

        }
    }

    const setMunicipio2 = async (val) => {
        setVisibleModal(false)
        facebookRegistroSesion(data.correo, data.nombre, data.foto, data.token, val.claveMunicipio)
    }

    const googleFacebook = async (correo) => {
        setCargando(true)

        const URL = `${API_URL}/sesionCiudadanoFacebook`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    correo: correo,
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                setTimeout(() => {
                    Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
                    Cookies.set("galleta", JSON.stringify(['true', datos[0].rol, datos[0].id, datos[0].correo]));
                    setCargando(false)
                }, 1000);

            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal")
            }

        } catch (error) {

        }
    }


    const facebookRegistroSesion = async (correo, nombre, foto, token, claveMunicipio) => {

        setCargando(true)

        const URL = `${API_URL}/registrarCiudadanoFacebook`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    correo: correo,
                    paterno: '',
                    materno: '',
                    nombre: nombre,
                    celular: '',
                    nacimiento: '',
                    password: token,
                    tipoSesion: 'Facebook',
                    urlFoto: foto,
                    claveMunicipio: claveMunicipio
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {

                const datos = resp.respuesta;
                setTimeout(() => {
                    Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
                    Cookies.set("galleta", JSON.stringify(['true', datos[0].rol, datos[0].id, datos[0].correo]));
                    setCargando(false)
                }, 1500);


            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal")
            }

        } catch (error) {

        }


    }

    function ColorLuminance(hex, lum) {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }

        return rgb;
    }

    return (
        <>
            <Cargando visible={cargando} />

            <div style={{ height: '100vh', width: '100vw', backgroundColor: color }}>

                <div style={{ height: 150, backgroundColor: color, width: '100%', overflow: 'hidden', textAlign: 'center', paddingTop: 40 }}>
                    <img alt="Modern Design"
                        src={`${API_URL}/getImagen/principalBlanco/${CLAVE_MUNICIPIO}`}
                        style={{ height: '100%', objectFit: 'contain' }} />
                    {/*    <Text style={{ fontWeight: 'bold', fontSize: 30, color: color }}>Zitácuaro</Text>*/}
                </div>

                <div style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <Form
                        name="normal_login"
                        //className="login-form"
                        style={{ width: '80%', display: 'flex', flexDirection: 'column', marginTop: 20 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >

                        <Form.Item
                            name="correo2"
                            style={{ display: 'none' }}
                        // rules={[{ required: true, message: 'Ingresa un correo electrónico valido!' }]}
                        >
                            <Input className='inputLogin' style={{ backgroundColor: color, borderBottomColor: 'white', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'white', backgroundColor: color }} />} placeholder="Correo o telefono" />

                        </Form.Item>

                        <Form.Item
                            name="correo"
                        //rules={[{ required: true, message: 'Ingresa un correo electrónico valido!' }]}
                        >

                            <Input autoComplete='off' className='inputLogin'
                                style={{ backgroundColor: color, borderBottomColor: 'white', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }}

                                prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'white', backgroundColor: color }} />} placeholder="Contraseña" style={{ backgroundColor: color, borderBottomColor: 'white', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                        // rules={[{ required: true, message: 'Ingresa un correo electrónico valido!' }]}
                        >

                            <Input.Password className='inputLogin' iconRender={visible => (!visible ? <EyeFilled style={{ color: 'white' }} /> : <EyeInvisibleFilled style={{ color: 'white' }} />)} prefix={<LockFilled className="site-form-item-icon" style={{ color: 'white', backgroundColor: color }} />} placeholder="Contraseña" style={{ backgroundColor: color, borderBottomColor: 'white', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
                        </Form.Item>


                        <Form.Item>

                            <Button
                                type="primary" htmlType="submit" icon={<LoginOutlined />} size='large' className="login-form-button" style={{ width: '80vw', marginTop: -10, backgroundColor: ColorLuminance(color, -0.2), borderColor: ColorLuminance(color, -0.2) }}>
                                Entrar
                            </Button>

                        </Form.Item>

                    </Form>

                </div>

                <div style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link to="/Registro">
                        <a href="" style={{ textDecoration: 'underline', color: 'white', fontWeight: 'bold', fontSize: 20, marginLeft: 8 }}>Crear Cuenta</a>
                    </Link>
                </div>

                <div style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                    <p style={{ color: 'white' }}>Tambien puedes iniciar sesión con:</p>
                </div>

                <div style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: -15 }}>
                    <div style={{ width: '90%', alignSelf: 'auto', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>

                    {data.loginLlavero == 1 ?
                            <Link to="/LlaveroSesion" style={{ width: '33%', alignSelf: 'center', textAlign: 'center' }}>
                                <Button
                                    type="primary" icon={<QrcodeOutlined />} size='large' htmlType="submit" className="login-form-button" style={{ width: '100%', backgroundColor: 'white', borderColor: color, color: 'black', marginTop: 10 }}>
                                    Llavero
                                </Button>
                            </Link>
                            : null
                        }



                        {data.loginGoogle == 1 ?
                            <GoogleLogin
                                clientId={clientId}
                                render={renderProps => (
                                    <Button
                                        type="primary" onClick={renderProps.onClick} icon={<GoogleOutlined />} size='large' className="login-form-button" style={{ width: '33%', backgroundColor: 'white', borderColor: color, color: '#db4a39', marginTop: 10 }}>
                                        Google
                                    </Button>
                                )}
                                buttonText="Login"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                            :
                            null
                        }


                        {data.loginFacebook == 1 ?
                            <FacebookLogin
                                appId={facebookId}
                                // autoLoad
                                callback={responseFacebook}
                                fields="name,email,picture"
                                disableMobileRedirect={true}
                                render={renderProps => (
                                    <Button
                                        type="primary" onClick={renderProps.onClick} icon={<FacebookFilled />} size='large' className="login-form-button" style={{ width: '33%', backgroundColor: 'white', borderColor: color, color: '#3b5998', marginTop: 10 }}>
                                        Facebook
                                    </Button>
                                )}
                            />
                            : null
                        }

                    </div>
                </div>

                <div style={{ height: 120, backgroundColor: color, width: '100%', overflow: 'hidden', textAlign: 'center', paddingTop: 40 }}>
                    <img alt="Modern Design"
                        src={`${API_URL}/getImagen/principal2Blanco/${CLAVE_MUNICIPIO}`}
                        style={{ height: '90%', objectFit: 'contain' }} />

                </div>



            </div>

            {visibleModal ?
                <ModalSelectMunicipio visible={visibleModal}
                    municipios={data.municipios}
                    onCreate={(e) => setMunicipio(e)}
                    onCancel={() => {
                        setVisibleModal(false)
                    }}
                />
                :
                null
            }

            {visibleModal2 ?
                <ModalSelectMunicipio visible={visibleModal2}
                    municipios={data.municipios}
                    onCreate={(e) => setMunicipio2(e)}
                    onCancel={() => {
                        setVisibleModal2(false)
                    }}
                />
                :
                null
            }


        </>
    )
}


function error() {
    Modal.error({
        title: 'Invalido!',
        content: 'No existe ningun registro con esas credenciales.',
    });
}



export default Login;
