import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider,DatePicker } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, EditFilled,ArrowRightOutlined,MailFilled,CalendarOutlined,CameraFilled,SaveFilled  } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory,useParams,useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, } from '../componentes/WebServices/Variables';
import Cargando  from '../componentes/Utilidades/Cargando'
import moment from 'moment';
const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();
    //let {correo,nombre,paterno,materno,celular,nacimiento,urlFoto} = useParams();
 

    const [calendar, setCalendar] = React.useState(false);
    const [continuar, setContinuar] = React.useState(false);
    const [continuar2, setContinuar2] = React.useState(false);
    const [cargando, setCargando] = React.useState(false)

    const [fotoEdit, setFotoEdit] = React.useState(false)

    const [data, setData] = React.useState({
        correo: 'ss',
        paterno: '',
        materno: '',
        nombre: '',
        celular: '',
        nacimiento: "01/01/1996",
        nacimientoMoment: "01/01/1996",
        urlFoto:'',
        nacimientoSelected: true,
        get:false,

        correoRespaldo: '',
        paternoRespaldo: '',
        maternoRespaldo: '',
        nombreRespaldo: '',
        celularRespaldo: '',
        nacimientoRespaldo: '',


        isvalidcorreo: true,
        isvalidcelular: true,
        inputColor1: '',
        inputColor2: '',
        isEditado: false,

        imagen: 'algo',
        imagenBaseSelected: '',
        imagenTypeSelected: '',
       
        idUsuario: '',

    });


    useEffect(() => {
        var input = navigator.onLine;
     
       const {nombre,paterno,materno,correo,nacimiento,celular,urlFoto,idUsuario} = location.state;
       //   console.log(nombre,paterno,materno,correo,nacimiento,celular)

           console.log(nacimiento,moment(nacimiento,'DD/MM/YYYY'))

        setData({
            ...data,
            correo: correo,
            paterno: paterno,
            materno: materno,
            nombre: nombre,
            celular: celular,
            nacimiento: nacimiento,
            nacimientoMoment:moment(nacimiento),
            urlFoto:urlFoto,
            get:true,
            idUsuario:idUsuario
          });

    //    alert(paterno)

    }, [])


    const onFinish = async (values) => {

    }


    const guardar = async (values) =>{
        console.log(values.nombre)
        setCargando(true)
       
        const URL = `${API_URL}/saveDatosPerfil`;
        try {
           
            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({                   
                    correo: values.correo.replace("\n", "").trim(),
                    paterno: values.paterno.replace("\n", "").trim(),
                    materno: values.materno.replace("\n", "").trim(),
                    nombre: values.nombre,
                    celular: data.celular.replace("\n", "").trim(),
                    nacimiento: values.nacimiento.format('DD/MM/YYYY'),
                    fotoBase:data.imagenBaseSelected,
                    fotoTipo:data.imagenTypeSelected,
                    urlFoto:`${API_URL}/getFotoPerfil/${data.idUsuario}`,
                    idUsuario: data.idUsuario,
                    fotoEdit:fotoEdit
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                setTimeout(() => {
                    setCargando(false)

                     history.goBack()
                     history.goBack()

                    alert(resp.respuesta)
                  }, 2000);
                

            } else if (resp.msg == "300") {
       
            }
            else if (resp.msg == "400") {
          
                alert("algo salio mal")
            }

        } catch (error) {

        }
    }

    return (
        <>
         <Cargando visible={cargando}/>
            {/*position:'fixed',top:0,width:'100%'*/}
            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => {
                    history.goBack()
                   // history.goBack()
                }}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Editar perfil</Text>
            </Header>


            <div style={{ width: '100vw', paddingBottom: '20px', alignItems: 'center', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column' }}>


                <div style={{ position:'relative',width: '90%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                    <Avatar size={110} icon={<UserOutlined />} style={{backgroundColor: 'black', opacity: 0.5}} src={data.urlFoto} />
                    <CameraFilled style={{ fontSize: 30, color: 'black',position:'absolute',zIndex:10, top: 35, left: 0, bottom: 0, right: 0,margin:'auto' }} />
               </div>


                 {data.get ?


                <Form
                    name="normal_login"
                    style={{ marginTop: '25px', width: '80%', display: 'flex', flexDirection: 'column' }}
                  //  initialValues={{ remember: true }}
                    initialValues={{
                        modifier: 'public',
                        correo:data.correo,
                        nombre:data.nombre,
                        paterno:data.paterno,
                        materno:data.materno,
                       // celular:data.celular,
                       
                      //  nacimiento:data.nacimientoMoment
                      }}
                    onFinish={guardar}
                >

                    <Form.Item
                        name="correo"
                        rules={[{ required: true, type: 'email', message: 'Ingresa un correo electrónico valido!' }]}
                    >
                        <Input  prefix={<MailFilled className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Correo" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
                    </Form.Item>

                    <Form.Item
                        name="paterno"
                        rules={[{ required: true,message: 'Ingresa un correo electrónico valido!' }]}
                    >
                        <Input  prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Apellido paterno" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
                    </Form.Item>

                    <Form.Item
                        name="materno"
                        rules={[{ required: true,  message: 'Ingresa un correo electrónico valido!' }]}
                    >
                        <Input  prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Apellido materno" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
                    </Form.Item>

                    <Form.Item
                        name="nombre"
                        rules={[{ required: true, message: 'Ingresa un correo electrónico valido!' }]}
                    >
                        <Input  prefix={<UserOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Nombre" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
                    </Form.Item>

                    {/*<Form.Item
                        name="nacimiento"
                        rules={[{ required: true, message: 'Ingresa un correo electrónico valido!' }]}
                    >
                        <Input defaultValue={data.nacimiento} prefix={<CalendarOutlined className="site-form-item-icon" style={{ color: 'grey' }} />} placeholder="Fecha de nacimiento" style={{ borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', padding: '8px', borderRadius: 0 }} />
                    </Form.Item>*/}
                     
                     
                <Form.Item
                name="nacimiento"
                rules={[{ required: true, message: 'Selecciona tu fecha de nacimiento!' }]}
              >
                <DatePicker
                  //defaultValue={data.nacimientoMoment} 
                  format={'DD/MM/YYYY'} inputReadOnly={true} onChange={(val) => { }} placeholder="Fecha de nacimiento" showToday={false} style={{ width: '100%', height: 40 }} />
                    </Form.Item>
         

                    <Form.Item>

                        <Button
                            type="primary" htmlType="submit"  icon={<SaveFilled/>} size='large' className="login-form-button" style={{ width: '80vw', marginTop: 10, backgroundColor: props.colorPrimary, borderColor: props.colorPrimary }}>
                            Guardar
                        </Button>

                    </Form.Item>


                </Form>

:
null
                 }
                 
            </div>


        </>
    )
}

export default Primero;