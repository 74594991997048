import React from "react";
import ReactDOM from "react-dom";

import { Layout, Menu, Avatar, Dropdown, Button, Space, Badge, Anchor, Drawer,  } from "antd";
import {
  UserOutlined,
   AppstoreOutlined,
   ProjectOutlined,
   UnorderedListOutlined,
   GroupOutlined,
   InfoCircleOutlined,
     BulbOutlined,
     SettingOutlined,
HomeOutlined,
FolderOpenOutlined,
SyncOutlined,
CommentOutlined,
DeploymentUnitOutlined
} from "@ant-design/icons";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';


const { Header, Sider, Content } = Layout;
const {SubMenu} = Menu;
const MenuPrincipal = (props) =>{
    return (
      <Menu theme='dark' mode="inline" defaultSelectedKeys={["1"]} className="menu-lateral">
      <Menu.Item key="1"  icon={<HomeOutlined />} style={{color:'white'}} className='menu-item'>
      Inicio 
      </Menu.Item>

      <Menu.Item key="2"  icon={<FolderOpenOutlined/>} style={{color:'white'}} className='menu-item'>
        Mi tablero  
      <Link to="/Dashboard/Tablero" /> 
      </Menu.Item>

      <Menu.Item key="3"  icon={<FolderOpenOutlined/>} style={{color:'white'}} className='menu-item'>
        Mis actividades  
      <Link to="/Dashboard/Tablero" /> 
      </Menu.Item>
      
      <Menu.Item key="4" icon={<BulbOutlined />} style={{color:'white'}} className='menu-item'>
      Ideas
      <Link to="/Dashboard/Proyectos" />
      </Menu.Item>

  
</Menu>
    )
}

export default MenuPrincipal;