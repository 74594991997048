import React from "react";
import ReactDOM from "react-dom";

import { Layout, Menu, Avatar, Dropdown, Button, Space, Badge, Anchor, Drawer,  } from "antd";
import {
  UserOutlined,
   AppstoreOutlined,
   ProjectOutlined,
   UnorderedListOutlined,
   GroupOutlined,
   InfoCircleOutlined,
     BulbOutlined,
     SettingOutlined,
HomeOutlined,
FolderOpenOutlined,
SyncOutlined,
CommentOutlined,
DeploymentUnitOutlined
} from "@ant-design/icons";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';

import iconoblanco from '../../recursos/images/iconoblanco.png';
import logoblanco from '../../recursos/images/logoblanco.png';

import MenuAdministrador from './MenusUsuarios/MenuAdministrador';
import MenuEmpresa from './MenusUsuarios/MenuEmpresa';
import MenuConsultor from './MenusUsuarios/MenuConsultor';
import MenuColaborador from './MenusUsuarios/MenuColaborador';

const { Header, Sider, Content } = Layout;
const {SubMenu} = Menu;
const MenuPrincipal = (props) =>{
  
    return (
       <div>

            {props.rol == "Administrador" ?
               <MenuAdministrador ultimaRuta={props.ultimaRuta} id={props.id} rol={props.rol}/>
            :
            <div>
              {props.rol == "Empresa" ?
                <MenuEmpresa ultimaRuta={props.ultimaRuta} id={props.id}/>
              :
               <div>

                  {props.rol == "Consultor" || props.rol == "ConsultorPrincipal"?
                    <MenuConsultor ultimaRuta={props.ultimaRuta} id={props.id} rol={props.rol}/>
                  :
                 
                    <MenuColaborador ultimaRuta={props.ultimaRuta} id={props.id} rol={props.rol}/>
                  
                  }
                
               </div>
              }
                

            </div>
            }

     
          </div>
      );
}

export default MenuPrincipal;