import React from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled, SendOutlined, StarFilled, SaveFilled } from '@ant-design/icons';
import { API_URL, } from '../WebServices/Variables';
const { Title, Text } = Typography;
const Cargando = ({ visible, tramite, folio, fecha, estadoCompleto, colorStatus, status, entendido, fotos }) => {

    return (
        <Modal
            visible={visible}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={null}
            closable={false}
            keyboard={false}
            style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', maxWidth: 350 }}
        >
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column', marginTop: 30 }}>
                <div style={{ width: '90%', marginTop: -20, textAlign: 'left' }}>
                    <Text style={{ color: 'black', fontSize: 22 }}>{tramite}</Text>
                </div>

                <div style={{ width: '90%', marginTop: 10, textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                    <Text style={{ color: 'black', fontSize: 17 }}>Folio: {folio}</Text>
                    <Text style={{ color: 'black', fontSize: 17 }}>Fecha: {fecha}</Text>

                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, alignItems: 'center' }}>
                        <div style={{ width: 14, height: 14, borderRadius: 7, backgroundColor: `${colorStatus}`, marginRight: 5 }}></div>
                        <Text style={{ color: '#171b27', fontSize: 17 }}>{status}</Text>
                    </div>

                    <Text style={{ color: 'black', fontSize: 17 }}>Detalles: {estadoCompleto}</Text>
                </div>

                <div style={{ width: '100%',marginTop:10}}>
                    <div className="outer" style={{ height: '100%', width: '100%' }}>
                        {fotos.map(ele => {
                            return (
                                <div style={{ width: 'auto', height: '100%', padding: 5, position: 'relative' }} className="inner">
                                    <img src={`${API_URL}/fotosReportes/`+ele} style={{ width: 100, height:100 }} />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <Divider style={{ width: '100%' }} />

                <div style={{ width: '100%', textAlign: 'center', marginTop: -15, }}>
                    <Button type="text" onClick={() => { entendido() }} className="login-form-button" style={{ textAlign: 'center', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'black', fontSize: 16 }}>
                        Entendido
                    </Button>
                </div>

            </div>

        </Modal>
    );

}

export default Cargando;