import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, useSearchParams, useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL,CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from "react-google-maps";
import { compose, withProps } from "recompose";
import querystring from 'querystring'


const { Header } = Layout;
const { Title, Text } = Typography;

const MapaCalor = (props) => {
    const history = useHistory()
    const googleMapRef = useRef(null);
    let googleMap = null;

    const { defaultLat, defaultLong, marcadores, idDireccion, idDepto } = useParams();
    //let { id } = useParams();

    let zoom = 13
    const [zoomChanged, setZoomChanged] = React.useState(13)

    const [data, setData] = React.useState({
        markerLat: 19.682915,
        markerLon: -101.172042,
        get: false,
        references: {},
        openInfoWindowMarkerId: '',
        defaultLat: defaultLat,
        defaultLong: defaultLong,
        defaultZoom: 13,
        marcadores: []
    });

    const markerList = [
        {
            lat: 19.4357797,
            lng: -100.3558299,
            icon: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Flag--Right-Chartreuse.png',
            info: '<div><h2>Info 1</h2><p>Lorem Ipsum is simply dummy text<br/> of the printing and typesetting industry.</p></div>',
            title: "Title 1"
        },
        {
            lat: 19.6830024,
            lng: -101.1719339,
            icon: 'https://cdn2.iconfinder.com/data/icons/IconsLandVistaMapMarkersIconsDemo/256/MapMarker_Marker_Outside_Chartreuse.png',
            info: '<div><h2>Info 2</h2><p>Lorem Ipsum is simply dummy text<br/> of the printing and typesetting industry.</p></div>',
            title: "Title 2"
        },
        {
            lat: 19.4373832,
            lng: -100.35794,
            icon: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Ball-Right-Azure.png',
            info: '<div><h2>Info 3</h2><p>Lorem Ipsum is simply dummy text<br/> of the printing and typesetting industry.</p></div>',
            title: "Title 3"
        },
        {
            lat: 19.4371056596554,
            lng: -100.357722006517,
            icon: 'https://cdn1.iconfinder.com/data/icons/Map-Markers-Icons-Demo-PNG/256/Map-Marker-Marker-Outside-Pink.png',
            info: '<div><h2>Info 4</h2><p>Lorem Ipsum is simply dummy text<br/> of the printing and typesetting industry.</p></div>',
            title: "Title 4"
        }
    ];


    useEffect(() => {

        getMarcadores()
        /*  googleMap = initGoogleMap();
          var bounds = new window.google.maps.LatLngBounds();
          markerList.map(x => {
              const marker = createMarker(x);
              bounds.extend(marker.position);
          });
          googleMap.fitBounds(bounds); // the map to contain all markers*/
    }, []);

    const getMarcadores = async () => {


        /*  var markers = marcadores.split("&");
          var arrayMarkers = []
          for(var i=0;i<markers.length;i++){
              var coor = markers[i].split(",");
              arrayMarkers.push({lat:parseFloat(coor[0]),lng:parseFloat(coor[1]),folio:coor[2]})
          }
          console.log(arrayMarkers)
          setData({
              ...data,
             marcadores:arrayMarkers
          })*/

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify({
                idDireccion: idDireccion,
                idDepto: idDepto,
                claveMunicipio:CLAVE_MUNICIPIO
            }),

            headers: { "Content-Type": "application/json" }
        };
        const URL = `${API_URL}/getMapaCalor`;

        try {
            const response = await fetchWithTimeout(URL, requestInfo, 5000);
            const resp = await response.json();
            if (resp.msg == "200") {
                const datos = resp.respuesta;
                console.log(datos)

                if (datos.length != 0) {

     
                        var arrayMarkers = []
                        for (var i = 0; i < datos.length; i++) {
                            arrayMarkers.push({ lat: parseFloat(datos[i].latitud), lng: parseFloat(datos[i].longitud), folio: datos[i].folio })
                        }

             
                        setData({
                            ...data,
                            marcadores: arrayMarkers
                        })
                    

                } else {
                    alert("No hay reportes para mostrar")
                }

            } else if (resp.msg == "300") {

            } else if (resp.msg == "400") {
                alert("algo salió mal")
            }
        }
        catch (error) {

        }

    }

    const showInfo = (index) => {
        setData({
            ...data,
            openInfoWindowMarkerId: index,
            defaultZoom: zoom
        })
    }

    const handleZoomChanged = () => {
        const zoomLevel = googleMapRef.current.getZoom();
        console.log(zoomLevel)
        zoom = zoomLevel
        //setZoomChanged(zoomLevel)
    }

    const MyMapComponent = compose(
        withProps({
            googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyACa2VMm6XaetkcJyx0kZBjLIJVemM8Qvc&v=3.exp&libraries=geometry,drawing,places",
            loadingElement: <div style={{ width: `100vw`, height: `100vh` }} />,
            containerElement: <div style={{ width: `100vw`, height: `100vh` }} />,
            mapElement: <div style={{ width: `100vw`, height: `100vh` }} />
        }),
        withScriptjs,
        withGoogleMap
    )(props => (
        <GoogleMap
            ref={googleMapRef}
            defaultZoom={data.defaultZoom}
            onZoomChanged={handleZoomChanged}
            defaultCenter={{ lat: parseFloat(defaultLat), lng: parseFloat(defaultLong) }}
        >
            {data.marcadores.map((item, index) => {
                
                return (
                    <Marker
                        key={index}
                        position={{ lat: item.lat, lng: item.lng }}
                        label={index.toString()}
                        //onClick={() => { handleToggleOpen(index) }}
                        onClick={() => { showInfo(index) }}

                    >
                        {(data.openInfoWindowMarkerId == index) &&
                            <InfoWindow
                            // onCloseClick={this.props.handleCloseCall}
                            >
                                <span>Folio: {item.folio}</span>
                            </InfoWindow>
                        }

                    </Marker>
                )
            })
            }
        </GoogleMap>
    ));



    return (
        <>
            <div style={{ flex: 1, width: '100vw', height: '100vh' }}
            //id="map"
            >
                <MyMapComponent />
            </div>


            {/*<div
                ref={googleMapRef}
                style={{ width: 600, height: 500 }}
/>*/}
        </>
    )
}

export default MapaCalor;

export async function fetchWithTimeout(url, options, timeout = 5000) {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
    ]);
}