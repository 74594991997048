import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled, SendOutlined, StarFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory,useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, } from '../componentes/WebServices/Variables';
const { Header } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();

    const [color1, setColor1] = React.useState('#D8D8D8');
    const [color2, setColor2] = React.useState('#D8D8D8');
    const [color3, setColor3] = React.useState('#D8D8D8');
    const [color4, setColor4] = React.useState('#D8D8D8');
    const [color5, setColor5] = React.useState('#D8D8D8');

    const [sel1, setSel1] = React.useState(false);
    const [sel2, setSel2] = React.useState(false);
    const [sel3, setSel3] = React.useState(false);
    const [sel4, setSel4] = React.useState(false);
    const [sel5, setSel5] = React.useState(false);

    const [cargando, setCargando] = React.useState(false)
    const [estrellas, setEstrellas] = React.useState(0);

    const [data, setData] = React.useState({
        comentario: '',
        idUsuario: '',
        correoUsuario: ''
    });

    useEffect(() => {
        const { idUsuario, correoUsuario } = location.state;
        setData({
            ...data,
            idUsuario: idUsuario,
            correoUsuario: correoUsuario
          });
      
 
        getCalif(idUsuario)

        return () => {

        };

    }, []);

    const getCalif = async (idUsuario) => {

        //let idUsuario = await AsyncStorage.getItem('idUsuario');
        const URL = `${API_URL}/getCalificacion`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    idUsuario: idUsuario
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                if (datos.length != 0) {

                    if (datos[0].estrellas == "1") {
                        setColor1('orange')
                        setSel1(true)
                        setEstrellas(1)
                    }
                    else if (datos[0].estrellas == "2") {
                        setColor1('orange')
                        setColor2('orange')
                        setSel2(true)
                        setSel1(true)
                        setEstrellas(2)
                    }
                    else if (datos[0].estrellas == "3") {
                        setColor1('orange')
                        setColor2('orange')
                        setColor3('orange')
                        setSel3(true)
                        setSel2(true)
                        setSel1(true)
                        setEstrellas(3)
                    }
                    else if (datos[0].estrellas == "4") {
                        setColor1('orange')
                        setColor2('orange')
                        setColor3('orange')
                        setColor4('orange')
                        setSel4(true)
                        setSel3(true)
                        setSel2(true)
                        setSel1(true)
                        setEstrellas(4)
                    }
                    else if (datos[0].estrellas == "5") {
                        setColor1('orange')
                        setColor2('orange')
                        setColor3('orange')
                        setColor4('orange')
                        setColor5('orange')
                        setSel5(true)
                        setSel4(true)
                        setSel3(true)
                        setSel2(true)
                        setSel1(true)
                        setEstrellas(5)
                    }

                    setData({
                        ...data,
                        comentario: datos[0].comentario
                    });

                }

            } else if (resp.msg == "300") {

            } else if (resp.msg == "400") {
                alert("algo salió mal")
            }

        } catch (error) {

        }
    }


    const enviar = async () => {
        if (estrellas != 0) {


            setCargando(true)

          //  let idUsuario = await AsyncStorage.getItem('idUsuario');
            const URL = `${API_URL}/enviarCalificacion`;
            try {

                const response = await fetch(URL, {
                    method: 'POST',
                    body: JSON.stringify({
                        estrellas: estrellas,
                        comentario: data.comentario,
                        idUsuario: data.idUsuario
                    }),

                    headers: { "Content-Type": "application/json" }
                });

                const resp = await response.json();
                console.log(resp)

                if (resp.msg == "200") {
                    setTimeout(() => {
                        setCargando(false)
                    }, 2000);

                } else if (resp.msg == "300") {
                    setTimeout(() => {
                        setCargando(false)
                    }, 2000);
                }
                else if (resp.msg == "400") {
                    setTimeout(() => {
                        setCargando(false)
                        alert("algo salio mal")
                    }, 2000);

                }

            } catch (error) {
                setCargando(false)
            }

        } else {
            alert("Para enviar una calificación debe seleccionar la cantidad de estrellas.")
        }


    }

    return (
        <>
            {/*position:'fixed',top:0,width:'100%'*/}
            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}></Text>
            </Header>

            <div style={{ width: '100vw', justifyContent: 'centeer', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '90%', marginTop: -20,textAlign:'center' }}>
                    <Text style={{ color: 'black', fontSize: 25 }}>Califícanos</Text>

                </div>
                <div style={{ width: '90%', marginTop: 10 }}>
                    <Text style={{ color: 'black' }}>Para seguir brindandote la mejor atencíon y servicio, es importante que nos dejes tu opinion de nuestra app.</Text>
                </div>

                <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'row', marginTop: 30 }}>
                    <StarFilled onClick={() => {
                        if (sel1) {

                            setSel1(false)
                            setSel2(false)
                            setSel3(false)
                            setSel4(false)
                            setSel5(false)
                            setColor1('#D8D8D8')
                            setColor2('#D8D8D8')
                            setColor3('#D8D8D8')
                            setColor4('#D8D8D8')
                            setColor5('#D8D8D8')
                            setEstrellas(0)
                        } else {

                            setColor1('orange')
                            setSel1(true)
                            setEstrellas(1)
                        }

                    }} style={{ color: color1, fontSize: 28, padding: 12 }} />
                    <StarFilled onClick={() => {
                        if (sel2) {
                            setSel2(false)
                            setSel3(false)
                            setSel4(false)
                            setSel5(false)
                            setColor2('#D8D8D8')
                            setColor3('#D8D8D8')
                            setColor4('#D8D8D8')
                            setColor5('#D8D8D8')
                            setEstrellas(1)
                        } else {
                            setColor1('orange')
                            setColor2('orange')
                            setSel2(true)
                            setSel1(true)
                            setEstrellas(2)
                        }


                    }} style={{ color: color2, fontSize: 28, padding: 12 }} />
                    <StarFilled onClick={() => {
                        if (sel3) {

                            setSel3(false)
                            setSel4(false)
                            setSel5(false)
                            setColor3('#D8D8D8')
                            setColor4('#D8D8D8')
                            setColor5('#D8D8D8')
                            setEstrellas(3)
                        } else {
                            setColor1('orange')
                            setColor2('orange')
                            setColor3('orange')
                            setSel3(true)
                            setSel2(true)
                            setSel1(true)
                            setEstrellas(3)
                        }

                    }} style={{ color: color3, fontSize: 28, padding: 12 }} />
                    <StarFilled onClick={() => {
                        if (sel4) {
                            setSel4(false)
                            setSel5(false)
                            setColor4('#D8D8D8')
                            setColor5('#D8D8D8')
                            setEstrellas(3)
                        } else {
                            setColor1('orange')
                            setColor2('orange')
                            setColor3('orange')
                            setColor4('orange')
                            setSel4(true)
                            setSel3(true)
                            setSel2(true)
                            setSel1(true)
                            setEstrellas(4)
                        }


                    }} style={{ color: color4, fontSize: 28, padding: 12 }} />
                    <StarFilled onClick={() => {
                        if (sel5) {
                            setSel5(false)
                            setColor5('#D8D8D8')
                            setEstrellas(4)
                        } else {
                            setColor1('orange')
                            setColor2('orange')
                            setColor3('orange')
                            setColor4('orange')
                            setColor5('orange')
                            setSel5(true)
                            setSel4(true)
                            setSel3(true)
                            setSel2(true)
                            setSel1(true)
                            setEstrellas(5)
                        }

                    }} style={{ color: color5, fontSize: 28, padding: 12 }} />
                </div>

                <div style={{ width: '80%', marginTop: 20 }}>
                    <TextArea value={data.comentario} rows={2} placeholder="Comentario" style={{ width: '100%', borderBottomColor: 'grey', borderTopColor: 'white', borderRightColor: 'white', borderLeftColor: 'white', borderRadius: 0 }} />
                </div>


                <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: 30 }}>
                    <Button
                        type="primary" icon={<SendOutlined />} size='large' onClick={()=>enviar()} className="login-form-button" style={{ width: '100%', marginTop: 10, backgroundColor: props.colorPrimary, borderColor: props.colorPrimary }}>
                        Enviar calificación
                    </Button>
                </div>

            </div>


        </>
    )
}

export default Primero;