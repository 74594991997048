import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
import { reactLocalStorage } from 'reactjs-localstorage';
import Cargando from '../componentes/Utilidades/Cargando'

const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();

    const [cargando, setCargando] = React.useState(false)

    const [data, setData] = React.useState({
        borradores: [],
        borradoresCuantos: 0,

    });

    useEffect(() => {
        check()

    }, [])

    const check = async () => {
        var value = reactLocalStorage.get('reportesLocales');
        if (value !== null) {
            setData({
                ...data,
                borradores: JSON.parse(value),
                borradoresCuantos: JSON.parse(value).length
            });
        }

    }

    const subir = () => {
            if (navigator.onLine) {
                checkValidacion()
            } else {
                alert("Para subir los reportes, es necesario tener internet")
            }

    }

    const checkValidacion = async () => {
        console.log('validar')
        setCargando(true)

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify({
                idUsuario: location.state.idUsuario,
            }),

            headers: { "Content-Type": "application/json" }
        };
        const URL = `${API_URL}/checkUsuarioValidacion`;

        try {
            const response = await fetchWithTimeout(URL, requestInfo, 5000);
            const resp = await response.json();
               console.log(resp)
            if (resp.msg == "200") {
               const datos = resp.respuesta;

               if(datos[0].validado==0){
               
                   setTimeout(() => {
                    setCargando(false)

                    history.push({
                        pathname: '/Dashboard/ValidarCuentaBorradores',
                        state: {borradores:data.borradores,idUsuario:location.state.idUsuario},
                    });
                  //  navigation.navigate('ValidarCuentaBorradores',{borradores:data.borradores})
                      

                }, 2000);
                     
               }else{
                  subirBorradores()
               }
     

            } else if (resp.msg == "300") {

            }
            else if (resp.msg == "400") {
                alert("algo salio mal")
            }

        }
        catch (error) {
            // enviarLocal()
            if (error == "TypeError: Network request failed") {
               // enviarLocal()
            }else if(error == "timeout"){
                setCargando(false)
                //setVisibleMenu(true)
            }

        }

    }

    const subirBorradores = async() =>{
        setCargando(true)
     
        const URL = `${API_URL}/subirBorradoresMunicipios`;
        try {
            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    idUsuario: location.state.idUsuario,
                    borradores:data.borradores,
                    claveMunicipio:CLAVE_MUNICIPIO
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                reactLocalStorage.remove('reportesLocales');
                setTimeout(() => {
                    setCargando(false)            
            
                    setData({
                        ...data,
                        borradores: [],
                        borradoresCuantos: 0,
                    });
                }, 2000);

            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal")
            }

        } catch (error) {

        }
    }

    return (
        <>
            {/*position:'fixed',top:0,width:'100%'*/}
            <Cargando visible={cargando} />

            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Borradores</Text>
                {data.borradoresCuantos > 0 ?
                <Button onClick={()=>subir()} type="primary" size='large' className="login-form-button" style={{ marginTop: 10, backgroundColor: props.colorPrimary, borderColor: props.colorPrimary, float: 'right', marginRight: '12px' }}>
                    Subir todos
                </Button>
                :
                null
                }

            </Header>


            <div style={{ width: '100vw', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>


                {data.borradoresCuantos > 0 ?
                    <>
                    {data.borradores.map((ele, index) => {
                        if (ele.status == "En proceso") {
                            var colorEstado = "#C5C5C5";
                        } else {
                            var colorEstado = "#33CA1F";
                        }

                        return (
                            <>

                                <div key={index} style={{ width: '90%', alignSelf: 'center', textAlign: 'left', height: 150, display: 'flex', flexDirection: 'column' }}>
                                    <Text style={{ marginTop: 10, fontSize: 17 }}>{ele.nombreTramite}</Text>
                                    <Text style={{ fontSize: 17 }}>Fecha: {("0" + (new Date(ele.fecha).getDate())).slice(-2) + "/" + ("0" + (new Date(ele.fecha).getMonth() + 1)).slice(-2) + "/" + new Date(ele.fecha).getFullYear() + " " + new Date(ele.fecha).getHours() + ":" + new Date(ele.fecha).getMinutes()}</Text>


                                    <div style={{ width: '90%',  }}>
                                        <div className="outer" style={{ height: '100%', width: '100%' }}>
                                            {ele.fotos.map(ele => {
                                                return (
                                                    <div style={{ width: 'auto', height: '100%', padding: 5, position: 'relative' }} className="inner">
                                                        <img src={ele.img} style={{ width: 100, height: 100 }} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>


                                </div>

                            </>
                        )

                    })}
                    </>
                    :
                    <Text style={{ color: '#B0AFAF', fontWeight: 'bold', fontSize: 15, textAlign: 'center',marginTop:40 }}>No hay borradores guardados</Text>
                }

            </div>


        </>
    )
}

export default Primero;


export async function fetchWithTimeout(url, options, timeout = 5000) {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
    ]);
}