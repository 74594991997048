import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory, Link } from "react-router-dom";
import { API_URL, } from '../componentes/WebServices/Variables';

const { Header } = Layout;
const { Title, Text } = Typography;

const Primero = (props) => {
    const history = useHistory()

    const [data, setData] = React.useState({
        reportes: '',
        preguntasFrecuentes: []
    });



    useEffect(() => {


        getPreguntas()

        return () => {

        };

    }, []);

    const getPreguntas = async () => {
        const URL = `${API_URL}/getPreguntasFrecuentes`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                setData({
                    ...data,
                    preguntasFrecuentes: datos
                });
            } else if (resp.msg == "300") {

            }
            else if (resp.msg == "400") {

                alert("algo salio mal")
            }

        } catch (error) {

        }
    }
    return (
        <>
            {/*position:'fixed',top:0,width:'100%'*/}
            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}>Ayuda</Text>
            </Header>


            

            <div style={{ width: '100vw', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '90%', }}>
                    <Text style={{ color: 'black', fontSize: 20, fontWeight: 'bold' }}>Preguntas frecuentes</Text>

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',marginTop:10 }}>
                {data.preguntasFrecuentes.map((item, index) => {
                    const textFromDB = item.respuesta.split('\n');
                    const rendered = textFromDB.map(x => <Text>{x}</Text>);
                    return (
                        <div key={index}>
                            <Text style={{ color: 'grey', fontSize: 15, alignSelf: 'center', marginTop: 10, fontFamily: 'Sen-Bold', width: '94%',marginRight:5,fontWeight:'bold' }}>{item.pregunta}</Text>
                            <Text style={{ fontFamily: 'Sen-Regular', color: 'grey', fontSize: 15, alignSelf: 'center', textAlign: 'left', marginTop: 5, width: '94%' }}>{rendered}</Text>

                        </div>
                    )
                })
                }
            </div>

                <a href={`${API_URL}/aviso-de-privacidad`} target="_blank" title="Aviso de privacidad">
                    <Button type="text" className="login-form-button" style={{ marginTop: 40, textAlign: 'center', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'black', fontSize: 16 }}>
                        Mira nuestro aviso de privacidad
                    </Button>
                </a>

                <a href={`${API_URL}/terminos-y-condiciones`} target="_blank" title="Aviso de privacidad">
                    <Button type="text" className="login-form-button" style={{ marginTop: 10, textAlign: 'center', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'black', fontSize: 16 }}>
                        Ver términos y condiciones
                    </Button>
                </a>

            </div>


        </>
    )
}

export default Primero;