import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
//import "../App.css";
import { Layout, Menu, Avatar, Dropdown, Button, Space, Badge, Anchor, Drawer, Select, Typography, Row, Col, Modal, Spin } from "antd";
import {
  BorderOuterOutlined,
  UserOutlined,
  MenuOutlined,
  BellOutlined,
  SettingOutlined,
  SettingFilled,
  LogoutOutlined,
  ExclamationCircleOutlined, FileTextFilled, SaveFilled, StarFilled, UserSwitchOutlined, PhoneFilled, ArrowLeftOutlined
} from "@ant-design/icons";
import iconoblanco from '../recursos/images/iconoblanco.png';
import logoblanco from '../recursos/images/logov1gris.png';
import { BrowserRouter as Router, Route, Switch, Link, Redirect, useHistory, withRouter } from 'react-router-dom';
//import history from '../componentes/Contexto/history';
import Icon from '@ant-design/icons'
import Rutas from '../componentes/Navegacion/Rutas'
import MenuPrincipal from '../componentes/Menus/MenuPrincipal'
import MenuMobile from '../componentes/Menus/MenuMobile'
import MenuPerfil from '../componentes/Menus/MenuPerfil'
import ModalSelectEmpresa from '../componentes/Utilidades/ModalSelectEmpresa'
import AuthApi from '../componentes/Contexto/AuthApi';
import Cookies, { set } from 'js-cookie';
import { API_URL, CLAVE_MUNICIPIO } from '../componentes/WebServices/Variables'
import Cargando from '../componentes/Utilidades/Cargando'


const { Option } = Select;
const { Header, Sider, Content } = Layout;
const { Text } = Typography;


const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () => elRef.current.scrollIntoView();

  return { executeScroll, elRef };
};

class Dashboard extends React.Component {
  static contextType = AuthApi;

  constructor(props) {
    super(props);
    console.log(props)


    this.contenido = React.createRef();
    this.lateral = React.createRef();
    this.bar = React.createRef();
    this.elScroll = utilizeScroll();
  }

  state = {
    collapsed: false,
    medida: '500px',
    drawer: false,
    hide: false,
    modalSelectVisible: false,
    width: 0, height: 0, mobil: false, marginSide: 200, altura: 0,
    loading: false,
    iconoSup: <MenuOutlined color='grey'/>,
    textoSup: 'Haz tu reporte:',
    tramites: [],
    isInHome: true,
    cargando: false
  };



  componentDidMount() {
    console.log("idUsuario: " + this.props.hsitory);

    this.getTramites()

    this.setState({
      isInHome: true
    });



    window.addEventListener('resize', this.updateDimensions);
    const medidaPx2 = this.bar.current.clientHeight;

    this.setState({
      altura: window.innerHeight - (medidaPx2 + 0)
    });


    this.elScroll.executeScroll();
    this.updateDimensions();

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    //alert(window.innerWidth+" "+window.innerHeight);
    if (window.innerWidth <= 768) {
      this.setState({ marginSide: 0, estilo: { width: '0px' }, hide: true });
    } else {
      this.setState({ marginSide: 200, hide: false });
    }
    // this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  toggle = () => {
    const medidaPx = this.contenido.current.clientWidth;

    const medidaPx2 = this.lateral.current.clientWidth;



    if (this.state.collapsed) {  //cuando se abre el menu, obtiene el width de cuando está cerrado
      const vari = parseInt(medidaPx) - 190;
      this.setState({
        //  collapsed: !this.state.collapsed,
        medida: vari
        //medida:'1650px'
      });

      setTimeout(() => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
      }, 100);

    } else {  //cuando se cierra el menu, obtiene el width de cuando está abierto
      const vari = parseInt(medidaPx) + 0;
      this.setState({
        collapsed: !this.state.collapsed,
        // medida:vari
        // medida:'1770px'
      });

      setTimeout(() => {
        this.setState({
          medida: vari
        });
      }, 100);
    }




    /*  this.setState({
        collapsed: !this.state.collapsed,
      }); */

  };

  cerrarDrawer = () => {
    this.setState({
      drawer: false
    })
  }




  cerrarSesion = () => {
    const Auth = this.context;

    Modal.confirm({
      title: 'Estás seguro que quieres cerrar tu sesión?',
      icon: <ExclamationCircleOutlined />,
      //content: 'Esto provocará que se eliminen los datos ya guardados con anterioridad.',
      okText: 'Si',
      cancelText: 'No',
      //onCancel={onCancel}

      onOk: () => {
        this.setState({ drawer: false, cargando: true });
        setTimeout(() => {

          Auth.setAuth({ autenticacion: false, rol: '', id: '', correo: '' });
          Cookies.remove("ultimaRuta");
          Object.keys(Cookies.get()).forEach(function (cookieName) {
            var neededAttributes = {
              // Here you pass the same attributes that were used when the cookie was created
              // and are required when removing the cookie
            };
            Cookies.remove(cookieName, neededAttributes);
          });

          this.setState({ cargando: false});
        }, 2000);
      },
    });

  }


  getTramites = async () => {

    const URL = `${API_URL}/getTramitesMunicipios`;
    try {
      const response = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({
          idUsuario: this.props.id,
          claveMunicipio: CLAVE_MUNICIPIO
        }),
        headers: { "Content-Type": "application/json" }
      });
      const resp = await response.json();

      if (resp.msg === "200") {
        const datos = resp.respuesta;
        console.log(datos)
        this.setState({ tramites: datos })
      } else if (resp.msg === "300") {

      }

      else if (resp.msg === "400") {

      }

    } catch (error) {

    }
  }

  render() {
    return (
      <>
        <Cargando visible={this.state.cargando} />
        <Router>

          <Layout>

            <Drawer
              title={<div style={{ width: '100%', height: '100%', textAlign: 'center' }}><img alt="Modern Design" src={`${API_URL}/getImagenMunicipioDrawer/${CLAVE_MUNICIPIO}`} style={{ width: '100px', alignSelf: 'center', margin: 'auto' }} /></div>}
              placement="left"
              drawerStyle={{ backgroundColor: 'white' }}
              closable={false}
              onClose={this.cerrarDrawer}
              visible={this.state.drawer}
            >


              <Link
                // to="/Dashboard/Perfil" 
                to={{ pathname: "/Dashboard/Perfil", state: { idUsuario: this.props.id, correo: this.props.correo } }}
              >
                <Button type="text" onClick={() => this.setState({ iconoSup: <ArrowLeftOutlined />, textoSup: 'Perfil', drawer: false, isInHome: false })} icon={<UserOutlined style={{ marginRight: 10 }} />} className="login-form-button" style={{ marginLeft: -20, textAlign: 'left', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'grey', fontSize: 16 }}>
                  Perfil
                </Button>
              </Link>

              <Link
                //to="/Dashboard/MisReportes"
                to={{ pathname: "/Dashboard/MisReportes", state: { idUsuario: this.props.id, correo: this.props.correo } }}>
                <Button type="text" onClick={() => this.setState({ drawer: false })} icon={<FileTextFilled style={{ marginRight: 10 }} />} className="login-form-button" style={{ marginLeft: -20, marginTop: 10, textAlign: 'left', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'grey', fontSize: 16 }}>
                  Mis reportes
                </Button>
              </Link>

              <Link
                //to="/Dashboard/Borradores"
                to={{ pathname: "/Dashboard/Borradores", state: { idUsuario: this.props.id, correo: this.props.correo } }}>

                <Button type="text" onClick={() => this.setState({ drawer: false })} icon={<SaveFilled style={{ marginRight: 10 }} />} className="login-form-button" style={{ marginLeft: -20, marginTop: 10, textAlign: 'left', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'grey', fontSize: 16 }}>
                  Borradores
                </Button>
              </Link>

              <Link
                 to={{ pathname: "/Dashboard/Directorio", state: { idUsuario: this.props.id, correo: this.props.correo } }}
              >
                <Button type="text" onClick={() => this.setState({ drawer: false })} icon={<PhoneFilled style={{ marginRight: 10 }} />} className="login-form-button" style={{ marginLeft: -20, marginTop: 10, textAlign: 'left', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'grey', fontSize: 16 }}>
                  Directorio
                </Button>
              </Link>

              <Link
                //to="/Dashboard/Calificanos"
                to={{ pathname: "/Dashboard/Calificanos", state: { idUsuario: this.props.id, correo: this.props.correo } }}>

                <Button type="text" onClick={() => this.setState({ drawer: false })} icon={<StarFilled style={{ marginRight: 10 }} />} className="login-form-button" style={{ marginLeft: -20, marginTop: 10, textAlign: 'left', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'grey', fontSize: 16 }}>
                  Calificanos
                </Button>
              </Link>

              <Link to="/Dashboard/Ayuda">
                <Button type="text" onClick={() => this.setState({ drawer: false })} icon={<UserSwitchOutlined style={{ marginRight: 10 }} />} className="login-form-button" style={{ marginLeft: -20, marginTop: 10, textAlign: 'left', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'grey', fontSize: 16 }}>
                  Ayuda
                </Button>
              </Link>

              <Link to="/Dashboard/Configuracion">
                <Button type="text" onClick={() => this.setState({ drawer: false })} icon={<SettingOutlined style={{ marginRight: 10 }} />} className="login-form-button" style={{ marginLeft: -20, marginTop: 10, textAlign: 'left', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'grey', fontSize: 16 }}>
                  Configuración
                </Button>
              </Link>


              <Button type="text" onClick={() => this.cerrarSesion()} icon={<LogoutOutlined style={{ marginRight: 10 }} />} className="login-form-button" style={{ marginLeft: -20, marginTop: 10, textAlign: 'left', width: '100%', backgroundColor: 'white', borderColor: 'white', color: 'grey', position: 'absolute', bottom: 30, left: 25, fontSize: 16 }}>
                Cerrar sesión
              </Button>

            </Drawer>

            {!this.state.hide ?

              /*<Sider
                style={{
                  overflow: 'auto',
                  height: '100vh',
                  position: 'fixed',
                  left: 0,
                }}
              >
                <div className="logo" style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>

                  {this.state.collapsed ?
                    <img alt="Modern Design" src={iconoblanco} style={{ width: '30px' }} />
                    :
                    <img alt="Modern Design" src={logoblanco} style={{ width: '70px' }} />
                  }
                </div>

                <MenuPrincipal rol={this.props.rol} ultimaRuta={this.props.ultimaRuta} id={this.props.id} />
                
                
              </Sider>*/
              null
              :

              null

            }

            <Layout className="site-layout" style={{ marginLeft: this.state.marginSide }}>

              <div ref={this.bar}>
                {/*    <Header className="site-layout-background" style={{ padding: 0 }}>

                  {this.state.hide ?
                    <>
                      <Button icon={this.state.iconoSup} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => this.setState({
                        drawer: true
                      })}></Button>
                      <Text style={{ fontSize: 16, marginLeft: 10 }}>{this.state.textoSup}</Text>
                    </>
                    :
                    null}

                </Header>*/}
              </div>

              <div ref={this.elScroll.elRef}></div>

              <Content style={{ overflow: 'initial', minHeight: this.state.altura, backgroundColor: 'white' }}>
                {/*padding:24 */}
                <div className="site-layout-background" style={{ padding: 0, textAlign: 'center', width: '100%', height: '100%', backgroundColor: 'white' }} >

                  {this.state.hide ?

                    <Rutas medida={this.state.medida} tramites={this.state.tramites} colorPrimary={this.props.colorPrimary} idUsuario={this.props.id} abrirMenu={() => this.setState({
                      drawer: true
                    })} />

                    :
                    <div style={{ width: '100%', height: '100%',alignItems:'center',justifyContent:'center' }}>
                      <h2 style={{marginTop:30}}>Contenido no disponible en este tamaño de pantalla</h2>
                    </div>
                  }

                </div>

              </Content>

            </Layout>
          </Layout>
        </Router>
      </>
    );
  }
}

export default Dashboard;