import React, { useEffect, useState,useRef } from 'react';
import { Form, Input, Button, Checkbox, Tabs, Row, Col, Modal, Space, Spin, Typography, Layout, Avatar, Divider } from 'antd';
import { UserOutlined, GoogleOutlined, ArrowLeftOutlined, PhoneFilled, SendOutlined, StarFilled } from '@ant-design/icons';
import historia from '../componentes/Contexto/history';
import { useHistory,useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'
import { API_URL, } from '../componentes/WebServices/Variables';
import CryptoJS from "crypto-js";
import Cookies from 'js-cookie';
import AuthApi from '../componentes/Contexto/AuthApi';
import Cargando  from '../componentes/Utilidades/Cargando'

const { Header } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const Primero = (props) => {
    const history = useHistory()
    const location = useLocation();
    const Auth = React.useContext(AuthApi);

    const numero1 = useRef()
    const numero2 = useRef()
    const numero3 = useRef()
    const numero4 = useRef()
    const numero5 = useRef()

    const [val1, setVal1] = React.useState('');
    const [val2, setVal2] = React.useState('');
    const [val3, setVal3] = React.useState('');
    const [val4, setVal4] = React.useState('');
    const [val5, setVal5] = React.useState('');

    const [cargando, setCargando] = React.useState(false)

    const [data, setData] = React.useState({
        correo: '',
        paterno: '',
        materno: '',
        nombre: '',
        celular: '',
        nacimiento: '',
        password: '',
        claveMunicipio:''
    });

    useEffect(() => {

       const {correo,paterno,materno,nombre,nacimiento,claveMunicipio,password,celular} = location.state;
        console.log(nombre,paterno,materno,correo,nacimiento,celular)
     setData({
         ...data,
         correo: correo,
         paterno: paterno,
         materno: materno,
         nombre: nombre,
         celular: celular,
         nacimiento: nacimiento,
         claveMunicipio:claveMunicipio,
         password:password
       });

        return () => {

        };

    }, []);

    const validar = async(val5) =>{
        var codigo = val1 + val2 + val3 + val4 + val5;
        setCargando(true)
        const URL = `${API_URL}/validarTokenRegistro`;
        try {
            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    numero: data.celular,
                    token: codigo
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {

                setTimeout(() => {                
                    registrarCiudadano()
                  
                }, 2000);

            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                alert("algo salio mal4")
                setCargando(false)
            }

        } catch (error) {
            setCargando(false)
        }
    }

    const registrarCiudadano = async () => {
        var contra = data.password.replace("\n", "").trim()
        let contraEncriptada = CryptoJS.AES.encrypt(contra, 'fookingSmooth').toString();

        const URL = `${API_URL}/registrarCiudadano`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({                   
                    correo: data.correo.replace("\n", "").trim(),
                    paterno: data.paterno.replace("\n", "").trim(),
                    materno: data.materno.replace("\n", "").trim(),
                    nombre: data.nombre,
                    celular: data.celular.replace("\n", "").trim(),
                    nacimiento: data.nacimiento.replace("\n", "").trim(),
                    password: contraEncriptada,
                    tipoSesion:'Normal',
                    urlFoto:`${API_URL}/getFotoPerfil/sinImagen`,
                    claveMunicipio:data.claveMunicipio
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            console.log(resp)

            if (resp.msg == "200") {
                const datos = resp.respuesta;
                setTimeout(() => {
                    setCargando(false)
                      Auth.setAuth({ autenticacion: true, rol: datos[0].id_rol, id: datos[0].id, correo: datos[0].correo });
                     Cookies.set("galleta", JSON.stringify(['true', datos[0].rol, datos[0].id, datos[0].correo]));
                }, 2000);

            } else if (resp.msg == "300") {
                setTimeout(() => {
                    setCargando(false)
                    alert(resp.respuesta)
                }, 2000);

            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal")
            }

        } catch (error) {

        }
    }



    const reenviar = async () => {

        setCargando(true)
        const URL = `${API_URL}/enviarTokenRegistro`;
        try {

            const response = await fetch(URL, {
                method: 'POST',
                body: JSON.stringify({
                    numero: data.celular
                }),

                headers: { "Content-Type": "application/json" }
            });

            const resp = await response.json();
            if (resp.msg == "200") {

                setTimeout(() => {
                    setCargando(false)
                   // setTimeLeft(60)
                }, 2000);

            } else if (resp.msg == "300") {
                setCargando(false)
                alert("algo salio mal")
            }
            else if (resp.msg == "400") {
                setCargando(false)
                alert("algo salio mal2")
            }

        } catch (error) {
            setCargando(false)
        }
    }
    
    return (
        <>
        <Cargando visible={cargando}/>
            {/*position:'fixed',top:0,width:'100%'*/}
            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: 'white' }}>
                <Button icon={<ArrowLeftOutlined style={{ fontSize: 20 }} />} style={{ borderColor: 'white', height: '100%', float: 'left', marginLeft: '10px' }} onClick={() => history.goBack()}></Button>
                <Text style={{ fontSize: 20, marginLeft: 15, float: 'left', marginTop: -2 }}></Text>
            </Header>

            <div style={{ width: '100vw', justifyContent: 'centeer', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '90%', marginTop: -20,textAlign:'center' }}>
                    <Text style={{ color: 'black', fontSize: 25 }}>Validar registro</Text>
                </div>
                <div style={{ width: '90%', marginTop: 10,textAlign:'center'  }}>
                    <Text style={{ color: 'black' }}>Para terminar tu registro, te hemos enviado un codigo a tu número de celular. Porfavor ingresalo.</Text>
                </div>

                <div style={{ width: '80%', height:55,display: 'flex', alignItems: 'center', justifyContent:'space-between', textAlign: 'center', flexDirection: 'row', marginTop: 30 }}>

                <Input ref={numero1} value={val1}  style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white',height:'100%' }}
                type="number"
                onChange={(val) => {
                
                    console.log(val.target.value)
                    if (val.target.value != "") {
                        setVal1(val.target.value)
                        numero2.current.focus()
                    } else {
                        setVal1(val.target.value)
                    }
                }}
                onKeyDown={({ nativeEvent }) => {
                    console.log(nativeEvent.key)
                    if (nativeEvent.key === 'Backspace') {

                        if (val1 != "") {
                            //setVal1('')
                            setVal1('')
                            setVal2('')
                            setVal3('')
                            setVal4('')
                            setVal5('')
                        } else {
                            setVal1('')
                            numero1.current.focus()
                        }

                    }
                }}
                />
                <Input ref={numero2} value={val2} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white',height:'100%' }}
                 type="number"
                 onChange={(val) => {
                    if (val.target.value != "") {
                        setVal2(val.target.value)
                        numero3.current.focus()
                    } else {
                        setVal2(val.target.value)
                    }

                }}
                onKeyDown={({ nativeEvent }) => {
                    console.log(nativeEvent)
                    if (nativeEvent.key === 'Backspace') {

                        if (val2 != "") {
                            //setVal1('')
                            setVal2('')
                            setVal3('')
                            setVal4('')
                            setVal5('')
                        } else {
                            setVal1('')
                            numero1.current.focus()
                        }

                    }
                }}
                />
                <Input ref={numero3} value={val3} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white',height:'100%' }}
               type="number"
               onChange={(val) => {
                    if (val.target.value != '') {
                        setVal3(val.target.value)
                        numero4.current.focus()
                    } else {
                        setVal3(val.target.value)
                    }

                }}
                onKeyDown={({ nativeEvent }) => {
                    console.log(nativeEvent.key)
                    if (nativeEvent.key === 'Backspace') {

                        if (val3 != "") {
                            //setVal1('')
                            setVal3('')
                            setVal4('')
                            setVal5('')
                        } else {
                            setVal2('')
                            numero2.current.focus()
                        }

                    }
                }}
                />
                <Input ref={numero4} value={val4} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white',height:'100%' }}
               type="number"
               onChange={(val) => {
                    if (val.target.value != '') {
                        setVal4(val.target.value)
                        numero5.current.focus()
                    } else {
                        setVal4(val.target.value)
                    }

                }}
                onKeyDown={({ nativeEvent }) => {
                    console.log(nativeEvent.key)
                    if (nativeEvent.key === 'Backspace') {

                        if (val4 != "") {
                            //setVal1('')
                            setVal4('')
                            setVal5('')
                        } else {
                            setVal3('')
                            numero3.current.focus()
                        }

                    }
                }}
                />
                <Input ref={numero5} value={val5} style={{ width: '15%', backgroundColor: '#fafafa', textAlign: 'center', backgroundColor: 'white',height:'100%' }}
                type="number"
                onChange={(val) => {

                    if (val.target.value != '') {
                        setVal5(val.target.value)
                        validar(val.target.value)
                    } else {
                        setVal5(val.target.value)
                    }

                    //  setVal5(val)

                }}
                onKeyDown={({ nativeEvent }) => { //solo si no es automatico
                    console.log(nativeEvent.key)
                    if (nativeEvent.key === 'Backspace') {
                        if (val5 != "") {
                            //setVal1('')
                            setVal5('')

                        } else {
                            setVal4('')
                            numero4.current.focus()
                        }

                    }
                }}
                />

                </div>


                <div style={{ width: '90%', marginTop: 15,textAlign:'center'  }}>
                    <Text style={{ color: 'black' }}>Si no recibiste el codigo, intente reenviarlo.</Text>
                </div>
                

                <div style={{ width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: 30 }}>
                    <Button
                        type="primary" icon={<SendOutlined />} size='large' 
                      onClick={()=>reenviar()} 
                        className="login-form-button" style={{ width: '100%', marginTop: 10, backgroundColor: 'black', borderColor: 'black' }}>
                        Reennviar
                    </Button>
                </div>

            </div>


        </>
    )
}

export default Primero;